import React from 'react';

type Props = {};

const NotificationSettings = (props: Props) => {
    return (
        <div className="flex flex-col">
            <h3 className="pb-6 pt-4 text-xl font-semibold text-[#1B1C19]">
                Notification settings
            </h3>
        </div>
    );
};

export default NotificationSettings;
