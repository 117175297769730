type Props = {
    status: string;
};

const StatusComponent = ({ status }: Props) => {
    let className: string = '';
    let label: string = '';
    switch (status) {
        case 'pending':
            className = 'bg-[#6495ed] text-[#6495ed] bg-opacity-20';
            label = 'Pending';
            break;
        case 'completed':
            className = 'bg-[#DEFD71] text-[#1B1C19]';
            label = 'Completed';
            break;
        case 'cancelled':
            className = 'bg-[#FF7E66] text-[#FF7E66] bg-opacity-20';
            label = 'Cancelled';
            break;
        case 'in_progress':
            className = 'bg-[#6495ed] text-[#6495ed] bg-opacity-20';
            label = 'In progress';
            break;
        case 'awaiting_approval':
            className = 'bg-[#97b5ed] text-[#97b5ed] bg-opacity-20';
            label = 'Awaiting approval';
            break;
        case 'rejected':
            className = 'bg-[#ff6868] text-[#ff6868] bg-opacity-20';
            label = 'Rejected';
            break;
        case 'approved':
            className = 'bg-[#06D7A0] text-[#06D7A0] bg-opacity-20';
            label = 'Approved';
            break;
        case 'published':
            className = 'bg-[#06D7A0] text-[#06D7A0] bg-opacity-20';
            label = 'Published';
            break;
    }
    return (
        <div
            className={
                'flex items-center justify-center rounded-md px-3 py-2 ' +
                className
            }
        >
            <p className={'text-xs font-bold '}>{label}</p>
        </div>
    );
};

export default StatusComponent;
