import PrimaryButton from 'src/components/buttons/PrimaryButton';
import {
    BillingInformationDTO,
    BillingInformationModel,
    CheckoutDTO,
    CheckoutLinkDTO,
    LinkCheckoutModel,
    StepModel,
} from '../Checkout.interface';
import { client } from 'src/utils/client';
import { CREATE_ORDER } from 'src/const/apiUrls';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

type Props = {
    linkCheckoutList: Array<LinkCheckoutModel>;
    billingInformation?: BillingInformationModel;
    steps: Array<StepModel>;
    activeStep: number;
    onStepChange: (step: number) => void;
};

const SummaryStep = ({
    billingInformation,
    linkCheckoutList,
    steps,
    activeStep,
    onStepChange,
}: Props) => {
    const navigate = useNavigate();

    const onSubmitHandler = async () => {
        let payload: CheckoutDTO | null = null;
        let linksData: Array<CheckoutLinkDTO> = [];
        linksData = linkCheckoutList.map((item: LinkCheckoutModel) => {
            let temp: CheckoutLinkDTO = {
                sku: item.sku,
                link: item.link ? item.link : '',
                topic: item.topic ? item.topic : '',
                url_source: item.urlSource ? item.urlSource : '',
                price: item.price ? item.price : 0,
                anchor_tag: item.anchorTag ? item.anchorTag : '',
                amount_of_words: item.articleWords ? item.articleWords : 0,
                note: item.notes ? item.notes : '',
                trust_links: item.trustLink ? item.trustLink === 'true' : true,
                target_placement: item.placement ? item.placement : 'anywhere',
            };
            return temp;
        });

        let billingInfo: BillingInformationDTO | null = null;
        if (billingInformation) {
            billingInfo = {
                business_name: billingInformation.businessName
                    ? billingInformation.businessName
                    : '',
                address: billingInformation.address
                    ? billingInformation.address
                    : '',
                city: billingInformation.city ? billingInformation.city : '',
                country: billingInformation.country
                    ? billingInformation.country
                    : '',
                email: billingInformation.email ? billingInformation.email : '',
                postal_code: billingInformation.postalCode
                    ? billingInformation.postalCode
                    : '',
                vat_number: billingInformation.vatNumber,
            };
        }

        if (linksData && billingInfo) {
            payload = {
                order_links: linksData,
                billing_info: billingInfo,
            };

            try {
                let createOrderResponse = await client.post(
                    CREATE_ORDER,
                    payload
                );
                if (createOrderResponse.status === 200) {
                    navigate('/confirmation');
                }
            } catch (error) {
                toast.error(
                    'Unexpected error happened. Please try again later.'
                );
            }
        }
    };
    return (
        <div className="flex h-full flex-col">
            <h5 className="my-10 text-xl font-semibold text-[#1B1C19]">
                Order details
            </h5>
            <div className="flex flex-col">
                <div className="flex flex-col gap-10 px-3">
                    {linkCheckoutList &&
                        linkCheckoutList.map((item: LinkCheckoutModel) => {
                            return (
                                <div
                                    key={item.id}
                                    className="flex flex-col gap-3"
                                >
                                    <div className="flex w-full flex-col justify-between xs:flex-row">
                                        <p className="w-full break-words text-lg font-semibold text-[#1B1C19] xs:w-2/3">
                                            {item.link}
                                        </p>
                                        <p className="w-full text-start text-base text-[#1B1C19] xs:w-1/3 xs:text-end">
                                            {item.price
                                                ? '€' + item.price.toFixed(2)
                                                : 0}
                                        </p>
                                    </div>
                                    <div className="flex flex-col gap-3">
                                        <div className="flex flex-col gap-1 sm:flex-row">
                                            <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                                                Topic of the article
                                            </p>
                                            <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                                                {item.topic}
                                            </p>
                                        </div>
                                        <div className="flex flex-col gap-1 sm:flex-row">
                                            <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                                                Anchor Tag
                                            </p>
                                            <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                                                {item.anchorTag}
                                            </p>
                                        </div>
                                        <div className="flex flex-col gap-1 sm:flex-row">
                                            <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                                                URL Source
                                            </p>
                                            <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                                                {item.urlSource}
                                            </p>
                                        </div>
                                        <div className="flex flex-col gap-1 sm:flex-row">
                                            <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                                                Target placement
                                            </p>
                                            <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                                                {item.placement}
                                            </p>
                                        </div>
                                        <div className="flex flex-col gap-1 sm:flex-row">
                                            <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                                                Amount of words Article needs
                                            </p>
                                            <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                                                {item.articleWords}
                                            </p>
                                        </div>
                                        <div className="flex flex-col gap-1 sm:flex-row">
                                            <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                                                Trust links?
                                            </p>
                                            <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                                                {item.trustLink === 'true'
                                                    ? 'True'
                                                    : 'False'}
                                            </p>
                                        </div>
                                        <div className="flex flex-col gap-1 sm:flex-row">
                                            <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                                                Notes
                                            </p>
                                            <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                                                {item.notes}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
                <div className="my-6 flex w-full flex-col gap-3 border-b border-t border-[#EBEBEB] py-6">
                    <h6 className="text-lg font-semibold">
                        Billing information
                    </h6>
                    <div className="flex flex-col gap-1 px-0 sm:flex-row sm:px-3">
                        <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                            Business name
                        </p>
                        <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                            {billingInformation
                                ? billingInformation.businessName
                                : ''}
                        </p>
                    </div>
                    <div className="flex flex-col gap-1 px-0 sm:flex-row sm:px-3">
                        <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                            Country
                        </p>
                        <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                            {billingInformation
                                ? billingInformation.country
                                : ''}
                        </p>
                    </div>
                    <div className="flex flex-col gap-1 px-0 sm:flex-row sm:px-3">
                        <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                            Address
                        </p>
                        <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                            {billingInformation
                                ? billingInformation.address
                                : ''}
                        </p>
                    </div>
                    <div className="flex flex-col gap-1 px-0 sm:flex-row sm:px-3">
                        <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                            VAT number
                        </p>
                        <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                            {billingInformation
                                ? billingInformation.vatNumber
                                : ''}
                        </p>
                    </div>
                    <div className="flex flex-col gap-1 px-0 sm:flex-row sm:px-3">
                        <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                            City
                        </p>
                        <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                            {billingInformation ? billingInformation.city : ''}
                        </p>
                    </div>
                    <div className="flex flex-col gap-1 px-0 sm:flex-row sm:px-3">
                        <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                            Postal code
                        </p>
                        <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                            {billingInformation
                                ? billingInformation.postalCode
                                : ''}
                        </p>
                    </div>
                    <div className="flex flex-col gap-1 px-0 sm:flex-row sm:px-3">
                        <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                            Invoice email
                        </p>
                        <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                            {billingInformation ? billingInformation.email : ''}
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-auto flex items-center justify-between pt-5">
                {activeStep !== 1 && (
                    <button
                        className="mr-auto text-base font-semibold text-[#06D7A0] hover:text-[#06d79fb4]"
                        onClick={() => onStepChange(activeStep - 1)}
                    >
                        Back
                    </button>
                )}
                {activeStep === steps.length && (
                    <PrimaryButton
                        onClick={onSubmitHandler}
                        className="ml-auto"
                        label="Order Now"
                    />
                )}
            </div>
        </div>
    );
};

export default SummaryStep;
