import { useEffect, useState } from 'react';
import { RelatedDomain } from '../../Home.interface';
import TableHeader from 'src/components/table/TableHeader';
import competitionImg from 'src/assets/icons/competition.svg';
import { DataGrid } from '@mui/x-data-grid';
import { LinearProgress } from '@mui/material';
import { columns } from './CompetitionColumns';

type Props = {
    domains: Array<RelatedDomain>;
    className?: string;
};

const CompetitionTable = ({ domains, className }: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setIsLoading(!domains);
    }, [domains]);

    return (
        <div className={'flex flex-col ' + className}>
            <TableHeader
                icon={<img src={competitionImg} alt="Competition" />}
                title="Related domains"
            ></TableHeader>
            <div className="d-flex h-full w-full items-center justify-center">
                {domains && (
                    <DataGrid
                        style={{ overflowX: 'hidden' }}
                        sx={{
                            background: '#FFF',
                            borderBottomRightRadius: '20px',
                            borderBottomLeftRadius: '20px',
                            borderTopLeftRadius: '0px',
                            borderTopRightRadius: '0px',
                            border: 'none',
                            overflowX: 'scroll',
                        }}
                        slots={{
                            loadingOverlay: LinearProgress,
                        }}
                        getRowId={(row: RelatedDomain) => row.Domain}
                        rows={domains.slice(0, 5)}
                        columns={columns}
                        disableRowSelectionOnClick
                        disableColumnMenu
                        loading={isLoading}
                        getRowClassName={(params: any) => {
                            let textClass = 'text-sm text-[#1B1C19] border-0 ';
                            let backPrimaryClass = 'bg-[#FFFFFF] ';
                            let backSecondaryClass = 'bg-[#FAFAFA] ';
                            return params.indexRelativeToCurrentPage % 2 === 0
                                ? textClass + backPrimaryClass
                                : textClass + backSecondaryClass;
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    page: 0,
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                    />
                )}
            </div>
        </div>
    );
};

export default CompetitionTable;
