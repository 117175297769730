import cross from 'src/assets/icons/cross-grey.svg';
import check from 'src/assets/icons/check-circle-solid.svg';
import PrimaryButton from '../buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { Cart } from 'src/utils/cart/Cart.interface';
import { useEffect, useState } from 'react';
import { removeFromCart } from 'src/utils/cart/cartController';

type Props = {
    productList: Array<Cart>;
    setProductList: (productList: Array<Cart>) => void;
};

const CartPopover = ({ productList, setProductList }: Props) => {
    const navigate = useNavigate();
    const [totalPrice, setTotalPrice] = useState<string>('0');

    useEffect(() => {
        let tempTotal: number = 0;
        if (productList) {
            productList.map((item: Cart) => (tempTotal += item.price));
        }
        setTotalPrice(tempTotal.toFixed(2));
    }, [productList]);

    const removeProduct = (productId?: string) => {
        if (productId) {
            let tempProductList: Array<Cart> = productList.filter(
                (item: Cart) => item.id !== productId
            );

            removeFromCart(productId);
            setProductList(tempProductList);
        }
    };

    return (
        <div className="flex flex-col p-6">
            <div className="flex flex-col gap-3">
                {productList &&
                    productList.map((item: Cart) => {
                        return (
                            <div
                                key={item.id}
                                className="flex items-center justify-between gap-3"
                            >
                                <div className="flex flex-1 items-center justify-between">
                                    <p className="break-all pr-6 text-base text-[#1B1C19]">
                                        {item.name ? item.name : ''}
                                    </p>
                                    <p className="text-xl font-semibold text-[#1B1C19]">
                                        {item.price
                                            ? '€' + item.price.toFixed(2)
                                            : ''}
                                    </p>
                                </div>
                                <button
                                    onClick={() => removeProduct(item.id)}
                                    className="border-0 bg-transparent"
                                >
                                    <img
                                        src={cross}
                                        alt="cross"
                                        className="h-6 w-6"
                                    />
                                </button>
                            </div>
                        );
                    })}
            </div>
            <div className="mt-6 flex flex-col border-t-2 pt-5">
                <div className="flex flex-col">
                    <div className="flex justify-between">
                        <p className="text-base font-bold text-[#1B1C19]">
                            Subtotal
                        </p>
                        <p className=" text-xl font-semibold text-[#1B1C19]">
                            {totalPrice ? '€' + totalPrice : ''}
                        </p>
                    </div>
                    <p className="text-sm font-normal text-[#24245F]">
                        VAT calculated at checkout
                    </p>
                    <div className="mb-8 mt-4 flex items-center gap-2 rounded-2xl bg-[#a3ed89] bg-opacity-25 px-2 py-5">
                        <img src={check} className="h-5 w-5" alt="Check" />
                        <p className="text-base font-normal text-[#00BB93]">
                            Content creation of the link is{' '}
                            <span className="font-bold">included</span> in the
                            price
                        </p>
                    </div>
                    <div className="flex justify-end">
                        <PrimaryButton
                            type="button"
                            label="Go to Checkout"
                            className="rounded-2xl font-bold"
                            onClick={() => navigate('/checkout')}
                        ></PrimaryButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartPopover;
