import {
    BillingInformationModel,
    LinkCheckoutModel,
    StepModel,
} from './Checkout.interface';
import CustomStepper from './stepper/CustomStepper';
import { useState } from 'react';
import { stepList } from './stepper/StepsList';
import LinkAdditionalInfoStep from './steps/LinkAdditionalInfoStep';
import BillingInformationStep from './steps/BillingInformationStep';
import SummaryStep from './steps/SummaryStep';
import { SelectFieldData } from 'src/components/formField/SelectField.interface';

type Props = {
    className?: string;
    linkCheckoutList: Array<LinkCheckoutModel>;
    setLinkCheckoutList: React.Dispatch<
        React.SetStateAction<Array<LinkCheckoutModel>>
    >;
    billingInformation?: BillingInformationModel;
    setBillingInformation: React.Dispatch<
        React.SetStateAction<BillingInformationModel | undefined>
    >;
    countries: Array<SelectFieldData> | undefined;
};

const CheckoutForms = ({
    className,
    linkCheckoutList,
    setLinkCheckoutList,
    setBillingInformation,
    billingInformation,
    countries,
}: Props) => {
    const [activeStep, setActiveStep] = useState<number>(1);
    const [steps, setSteps] = useState<Array<StepModel>>(stepList);

    const setCurrentStepValid = (valid: boolean) => {
        let tempList: Array<StepModel> = steps.map((item: StepModel) => {
            if (item.id === activeStep) {
                return {
                    ...item,
                    isValid: valid,
                };
            }
            return {
                ...item,
            };
        });
        setSteps(tempList);
    };

    const onStepChange = (nextStep: number) => {
        let tempList: Array<StepModel> = steps.map((item: StepModel) => {
            let tempStep: StepModel = {
                ...item,
                isActive: false,
            };

            if (activeStep < nextStep && item.id === nextStep - 1) {
                tempStep.isFinished = true;
            }

            if (activeStep >= nextStep && item.id === nextStep) {
                tempStep.isFinished = false;
            }

            if (item.id === nextStep) {
                tempStep.isActive = true;
            }

            return tempStep;
        });

        setActiveStep(nextStep);
        setSteps(tempList);
    };

    const navigateTo = (nextStep: number, currentStepValid?: boolean) => {
        if (currentStepValid) {
            onStepChange(nextStep);
            return;
        }
        let currentStep = steps.find(
            (item: StepModel) => item.id === activeStep
        );

        if (currentStep?.isValid) {
            onStepChange(nextStep);
        }
    };

    const getComponent = (stepId: number) => {
        let component = <div></div>;
        switch (stepId) {
            case 1:
                component = (
                    <LinkAdditionalInfoStep
                        linkCheckoutList={linkCheckoutList}
                        setLinkCheckoutList={setLinkCheckoutList}
                        setCurrentStepValid={setCurrentStepValid}
                        steps={steps}
                        activeStep={activeStep}
                        navigateTo={navigateTo}
                    />
                );
                break;
            case 2:
                component = (
                    <BillingInformationStep
                        billingInformation={billingInformation}
                        setBillingInformation={setBillingInformation}
                        setCurrentStepValid={setCurrentStepValid}
                        steps={steps}
                        activeStep={activeStep}
                        navigateTo={navigateTo}
                        onStepChange={onStepChange}
                        countries={countries}
                    />
                );
                break;
            case 3:
                component = (
                    <SummaryStep
                        linkCheckoutList={linkCheckoutList}
                        billingInformation={billingInformation}
                        steps={steps}
                        activeStep={activeStep}
                        onStepChange={onStepChange}
                    />
                );
                break;
        }
        return component;
    };

    return (
        <div className={className}>
            <CustomStepper steps={steps}></CustomStepper>
            <div className="h-full">{getComponent(activeStep)}</div>
        </div>
    );
};

export default CheckoutForms;
