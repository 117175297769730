import { Dialog, DialogContent } from '@mui/material';
import { useForm } from 'react-hook-form';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import SecondaryButton from 'src/components/buttons/SecondaryButton';
import { ChangeDomainDto } from '../Home.interface';
import FormField from 'src/components/formField/FormField';
import { client } from 'src/utils/client';
import { toast } from 'react-toastify';
import { UPDATE_USER } from 'src/const/apiUrls';
import { useEffect } from 'react';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    focusedDomain: string;
    reloadHomePageData: () => void;
};

const FocusDomainDialog = ({
    isOpen,
    onClose,
    focusedDomain,
    reloadHomePageData,
}: Props) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<ChangeDomainDto>({
        defaultValues: {
            focused_domain: focusedDomain,
        },
    });

    useEffect(() => {
        reset({ focused_domain: focusedDomain });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusedDomain]);

    const removeProtocolAndSubdomain = (url:string) => {
        // Remove "http://" or "https://"
        url = url.replace(/^(https?|ftp):\/\//, '');
        
        // Remove "www."
        url = url.replace(/^(www\.)?/, '');
        
        return url;
    }

    const handleSubmitHandler = async (data: ChangeDomainDto) => {
        try {
            data.focused_domain = removeProtocolAndSubdomain(data.focused_domain);
            let resp = await client.post(UPDATE_USER, {
                data: data,
            });
            if (resp.data && resp.data.success) {
                reloadHomePageData();
                toast.success('You have successfully updated information.');
                onClose();
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong!');
        }
    };

    return (
        <Dialog onClose={onClose} open={isOpen} fullWidth className="">
            <form onSubmit={handleSubmit(handleSubmitHandler)}>
                <DialogContent className="flex flex-col gap-5">
                    <h1 className="text-xl font-bold">Change focus domain</h1>
                    <FormField
                        label="New focus domain"
                        name="focused_domain"
                        type="text"
                        placeholder="Enter the New focus domain"
                        register={register}
                        required
                        className="w-full"
                        errors={errors}
                    ></FormField>
                    <div className="flex items-center justify-end gap-5">
                        <SecondaryButton
                            type="button"
                            label="Cancel"
                            onClick={() => {
                                onClose();
                                reset();
                            }}
                        />
                        <PrimaryButton type="submit" label="Confirm" />
                    </div>
                </DialogContent>
            </form>
        </Dialog>
    );
};

export default FocusDomainDialog;
