import {
    GridColDef,
} from '@mui/x-data-grid';

import check from 'src/assets/icons/MaterialSymbolsCheckCircle.svg';
import cross from 'src/assets/icons/TdesignCloseCircleFilled.svg';

export const columns: GridColDef[] = [
    {
        field: 'isLive',
        headerName: 'Is link live?',
        headerClassName: 'text-xs text-[#708191]',
        flex: 0.1,
        sortable: false,
        minWidth: 100,
        renderCell: (params) => {
            let component = params.row.liveLink ? <img src={check} alt="New" className=" green-icon h-6 w-6" /> 
                : <img src={cross} alt="New" className=" red-icon h-6 w-6" />
            return component;
        }   
    },
    {
        field: 'liveLink',
        headerName: 'Live link',
        headerClassName: 'text-xs text-[#708191]',
        flex: 1,
        minWidth: 200,
        renderCell: (params) => {
            return params.value ? <a className='underline' href={params.value} target="_blank" rel="noopener noreferrer">{params.value}</a> : <div></div>
        }
    },
    {
        field: 'link',
        type:'string',
        align:'center',
        headerAlign:'center',
        headerName: 'Domain',
        headerClassName: 'text-xs text-[#708191] ',
        flex: 1,
        sortable: true,
        minWidth: 200,
        renderCell: (params) => (
            <a className='underline' href={'https://'+params.value} target="_blank" rel="noopener noreferrer">{params.value}</a>
          )
    },
    {
        field: 'anchorTag',
        headerName: 'Anchor Tag',
        headerClassName: 'text-xs text-[#708191]',
        flex: 0.7,
        minWidth: 110,
    },
    {
        field: 'topic',
        headerName: 'Topic',
        headerClassName: 'text-xs text-[#708191]',
        flex: 0.7,
        minWidth: 100,
    },
    {
        field: 'urlSource',
        headerName: 'Url source',
        headerClassName: 'text-xs text-[#708191]',
        flex: 0.7,
        minWidth: 120,
    },
    {
        field: 'category',
        headerName: 'Category',
        headerClassName: 'text-xs text-[#708191]',
        flex: 1,
        minWidth: 200,
    },
];
