import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import SecondaryButton from 'src/components/buttons/SecondaryButton';
import FormField from 'src/components/formField/FormField';
import {
    GET_ALL_CATEGORIES,
    GET_ALL_COUNTRIES,
    GET_USER,
    UPDATE_USER,
} from 'src/const/apiUrls';
import { UpdateUserDTO } from 'src/pages/authentication/Authentication.interface';
import { client } from 'src/utils/client';
import { User } from '../Profile.interface';
import { SelectFieldData } from 'src/components/formField/SelectField.interface';
import { Category } from 'src/interfaces/Category.interface';
import Multiselect from 'multiselect-react-dropdown';
import { Countries } from 'src/interfaces/Country.interface';
import SelectField from 'src/components/formField/SelectField';

const CompanyInfo = () => {
    const [user, setUser] = useState<User>();
    const [countries, setCountries] = useState<Array<SelectFieldData>>([]);
    const [categories, setCategories] = useState<Array<Category>>([]);
    const [selectedCategories, setSelectedCategories] = useState<
        Array<Category>
    >([]);
    const [errorCategory, setErrorCategory] = useState<string>('');

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<UpdateUserDTO>();

    const onSubmit = async (data: UpdateUserDTO) => {
        if (!selectedCategories || selectedCategories.length === 0) {
            setErrorCategory('Required field');
            return;
        } else {
            data.favorite_categories = selectedCategories;
            setErrorCategory('');
        }
        try {
            let resp = await client.post(UPDATE_USER, {
                data: data,
            });
            if (resp.data && resp.data.success) {
                toast.success('You have successfully updated information.');
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong!');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                let resp = await client.get(GET_USER);
                if (resp && resp.data) {
                    setUser(resp.data);
                }
            } catch (error) {
                console.log(error);
                toast.error('Something went wrong!');
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (user) {
            setValue('company_legal_name', user.companyLegalName);
            setValue('address', user.address);
            setValue('city', user.city);
            setValue('company_website', user.companyWebsite);
            setValue('country', user.country);
            setValue('display_name', user.displayName);
            setValue('email', user.email);
            setValue('phone_number', user.phoneNumber);
            setValue('postal_code', user.postalCode);
            setValue('vat_number', user.vatNumber);
            setValue('focused_domain', user.focusedDomain);
            setValue('tax_free', user.taxFree);
            setSelectedCategories(user.favoriteCategories);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let resp = await client.get(GET_ALL_CATEGORIES);
                if (resp && resp.data) {
                    setCategories(resp.data);
                }
            } catch (error) {
                console.log(error);
                toast.error('Something went wrong!');
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let resp = await client.get(GET_ALL_COUNTRIES);
                if (resp && resp.data) {
                    let tempCountries: Countries = resp.data;
                    let countryKeys = Object.keys(tempCountries);
                    let selectCountries: Array<SelectFieldData> =
                        countryKeys.map((item: string) => {
                            return {
                                label: tempCountries[`${item}`],
                                value: item,
                            };
                        });
                    setCountries([
                        { value: '', label: '' },
                        ...selectCountries,
                    ]);
                }
            } catch (error) {
                console.log(error);
                toast.error('Something went wrong!');
            }
        };
        fetchData();
    }, []);

    return (
        <div className="flex flex-col">
            <h3 className="pb-6 pt-4 text-xl font-semibold text-[#1B1C19]">
                Company information
            </h3>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex w-full flex-col gap-6"
            >
                <div className="flex w-full flex-col gap-5 md:flex-row">
                    <FormField
                        name="email"
                        register={register}
                        type="email"
                        label="E-mail"
                        placeholder="Enter your e-mail"
                        required
                        errors={errors}
                        className="w-full"
                    />
                    <FormField
                        name="company_website"
                        register={register}
                        type="text"
                        label="Company website"
                        placeholder="Enter your company website"
                        required
                        errors={errors}
                        className="w-full"
                    />
                </div>
                <div className="flex w-full flex-col gap-6 md:flex-row">
                    <FormField
                        name="company_legal_name"
                        register={register}
                        type="text"
                        label="Company legal name"
                        placeholder="Enter your company legal name"
                        required
                        errors={errors}
                        className="w-full"
                    />

                    <FormField
                        name="display_name"
                        register={register}
                        type="text"
                        label="Company display name"
                        placeholder="Enter your company display name"
                        required
                        errors={errors}
                        className="w-full"
                    />
                </div>
                <div className="flex w-full flex-col gap-6 md:flex-row">
                    <FormField
                        name="address"
                        register={register}
                        type="text"
                        label="Address"
                        placeholder="Enter your address"
                        required
                        errors={errors}
                        className="w-full"
                    />

                    <FormField
                        name="postal_code"
                        register={register}
                        type="text"
                        label="Postal code"
                        placeholder="Enter your postal code"
                        required
                        errors={errors}
                        className="w-full"
                    />
                </div>

                <div className="flex w-full flex-col gap-6 md:flex-row">
                    <FormField
                        name="city"
                        register={register}
                        type="text"
                        label="City"
                        placeholder="Enter your city"
                        required
                        errors={errors}
                        className="w-full"
                    />

                    <SelectField
                        className="w-full"
                        title="Country"
                        name="country"
                        required
                        register={register}
                        items={countries}
                        errors={errors}
                    />
                </div>

                <div className="flex w-full flex-col gap-6 md:flex-row">
                    <FormField
                        name="phone_number"
                        register={register}
                        type="text"
                        label="Phone number"
                        placeholder="Enter your phone number"
                        required
                        errors={errors}
                        className="w-full"
                    />
                    <FormField
                        name="vat_number"
                        register={register}
                        type="text"
                        label="VAT ID number"
                        placeholder="Enter your VAT ID number"
                        errors={errors}
                        className="w-full"
                    />
                </div>
                <FormField
                    name="focused_domain"
                    register={register}
                    type="text"
                    label="Domain in focus"
                    placeholder="Enter your domain in focus"
                    required
                    errors={errors}
                    className="w-full"
                />

                <div className={'flex w-full flex-col gap-2'}>
                    <label className="text-sm font-bold text-[#1B1C19]">
                        Category
                        <span className={'text-sm font-bold text-[#FF7E66]'}>
                            {' '}
                            *
                        </span>
                        <span className="pl-2 text-sm font-medium">
                            {' '}
                            (max 5 categories)
                        </span>
                    </label>
                    <Multiselect
                        className="focus:border-1 w-full rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-[6px] text-[#708191] focus:border-[#06D7A0]"
                        options={categories}
                        selectedValues={selectedCategories}
                        displayValue="name"
                        selectionLimit={5}
                        placeholder="Category"
                        onSelect={(selectedList, selectedItem) => {
                            let temp: Array<Category> = [...selectedCategories];
                            temp.push(selectedItem);
                            setSelectedCategories(temp);
                        }}
                        onRemove={(selectedList, selectedItem) => {
                            let temp: Array<Category> =
                                selectedCategories.filter(
                                    (item: Category) =>
                                        item.id !== selectedItem.id
                                );
                            setSelectedCategories(temp);
                        }}
                        showArrow
                        showCheckbox
                    />
                    <span className="text-red-700">
                        {errorCategory && errorCategory}
                    </span>
                </div>
                <div className="flex w-full flex-col gap-3 sm:flex-row">
                    <label
                        htmlFor="tax-free"
                        className="flex items-center gap-2 text-base font-semibold text-[#1B1C19]"
                    >
                        <input
                            className="h-5 w-5"
                            type="checkbox"
                            id="tax-free"
                            {...register('tax_free')}
                        />
                        I am free from VAT
                    </label>
                </div>

                <div className="flex justify-end gap-8">
                    <SecondaryButton
                        label="Cancel"
                        type="button"
                        onClick={() => {}}
                    />

                    <PrimaryButton
                        type={'submit'}
                        label="Submit"
                        className="w-fit"
                    />
                </div>
            </form>
        </div>
    );
};

export default CompanyInfo;
