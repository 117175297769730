import Header from 'src/components/layout/Header';
import { useState } from 'react';
import CompanyInfo from './tabs/CompanyInfo';
import ChangePassword from './tabs/ChangePassword';
import NotificationSettings from './tabs/NotificationSettings';

const Profile = () => {
    const [activeIndex, setActiveIndex] = useState<number>(0);

    return (
        <div className="flex flex-col gap-8">
            <Header title="Profile settings" />
            <div className="flex w-full">
                <button
                    className={
                        activeIndex === 0
                            ? 'w-1/2 rounded-bl-2xl rounded-tl-2xl border bg-[#06D7A0] px-6 py-4 text-xs text-white sm:text-base'
                            : 'w-1/2 rounded-bl-2xl rounded-tl-2xl border px-6 py-4 text-xs text-[#708191] sm:text-base'
                    }
                    onClick={() => setActiveIndex(0)}
                >
                    Company Information
                </button>
                <button
                    className={
                        activeIndex === 1
                            ? 'w-1/2 rounded-br-2xl rounded-tr-2xl border-b border-r border-t bg-[#06D7A0] px-6 py-4 text-xs text-white sm:text-base'
                            : 'w-1/2 rounded-br-2xl rounded-tr-2xl border-b border-r border-t px-6 py-4 text-xs text-[#708191] sm:text-base'
                    }
                    onClick={() => setActiveIndex(1)}
                >
                    Change Password
                </button>
                {/* <button
                    className={
                        activeIndex === 2
                            ? 'w-1/3 rounded-br-2xl rounded-tr-2xl border bg-[#06D7A0] px-6 py-4 text-xs text-white sm:text-base'
                            : 'w-1/3 rounded-br-2xl rounded-tr-2xl border px-6 py-4 text-xs text-[#708191] sm:text-base'
                    }
                    onClick={() => setActiveIndex(2)}
                >
                    Notification settings
                </button> */}
            </div>
            <div className="mt-5 w-full rounded-3xl bg-[#fff] px-6 py-4">
                {activeIndex === 0 && <CompanyInfo />}
                {activeIndex === 1 && <ChangePassword />}
                {activeIndex === 2 && <NotificationSettings />}
            </div>
        </div>
    );
};

export default Profile;
