import { StepModel } from "../Checkout.interface";

export const stepList:Array<StepModel> = [
    {
        id:1,
        title:'Cart',
        subtitle:'Add more additional information',
        isActive:true,
        divider:true,
        isFinished:false,
        isValid:false
    },
    {
        id:2,
        title:'Billing',
        subtitle:'Billing information',
        isActive:false,
        divider:true,
        isFinished:false,
        isValid:false
    },
    {
        id:3,
        title:'Summary',
        subtitle:'Summary of your order',
        isActive:false,
        divider:false,
        isFinished:false,
        isValid:false
    }
]