import { ReactNode } from 'react';
import searchIcon from 'src/assets/icons/search-icon.svg';

type Props = {
    title?: string;
    icon?: ReactNode;
    showSearch?: boolean;
    setSearch?: React.Dispatch<React.SetStateAction<string>>;
    totalRows?: number;
    searchTotalRows?: number;
};

const TableHeader = ({
    title,
    icon,
    showSearch,
    setSearch,
    totalRows,
    searchTotalRows,
}: Props) => {
    return (
        <div className="flex justify-between gap-2 rounded-t-3xl bg-white px-3 pb-6 pt-4">
            {(title || icon) && (
                <div className="flex items-center gap-4">
                    {icon ? icon : null}
                    {title && (
                        <p className="text-xl font-bold text-[#1B1C19]">
                            {title}
                        </p>
                    )}
                </div>
            )}
            {showSearch && (
                <div className="flex flex-col gap-6 xs:flex-row xs:items-center">
                    <div className="flex items-center rounded-3xl bg-[#FAFAFA] px-4 py-3">
                        <img
                            className="mr-3 h-3 w-3"
                            src={searchIcon}
                            alt="Search icon"
                        />
                        <input
                            className="bg-transparent"
                            type="text"
                            placeholder="Search"
                            onChange={(event: any) => {
                                if (setSearch) {
                                    setSearch(event.target.value);
                                }
                            }}
                        />
                    </div>
                    <p>
                        <span className="text-sm font-normal text-[#1F1F1F]">
                            {searchTotalRows} matches
                        </span>{' '}
                        <span className="text-sm text-[#708191]">
                            out of {totalRows} media
                        </span>
                    </p>
                </div>
            )}
        </div>
    );
};

export default TableHeader;
