import { LinkCheckoutModel } from "src/pages/checkout/Checkout.interface";

let key = 'linkInfo'

export const saveLinkAdditionalInfo = (linkList:Array<LinkCheckoutModel>) =>{
    if (typeof window !== 'undefined' && linkList) {
        window.localStorage.setItem(key, JSON.stringify(linkList));
    }
}

export const getLinkAdditionalInfo = () =>{
    if (typeof window !== 'undefined' ) {
        let localStorageString = window.localStorage.getItem(key);
        if (localStorageString) {
            let linkList: any =
                JSON.parse(localStorageString);

            let tempLinkList: Array<LinkCheckoutModel> = linkList.map((item:any)=>{
                let tempPrice = typeof item.price === 'string'? Number.parseFloat(item.price) : item.price;
                return {
                    ...item,
                    price:tempPrice
                }
            })
            return tempLinkList;
        }
        return [];
    }
}
export const removeLinkAdditionalInfo = () =>{
    if (typeof window !== 'undefined' ) {
        window.localStorage.removeItem(key);
    }
}