import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { MARK_AS_READ_NOTIFICATION } from 'src/const/apiUrls';
import { NotificationContext } from 'src/context/NotificationContext';
import { NotificationModel } from 'src/interfaces/Notification.interface';
import { client } from 'src/utils/client';

type Props = {};

const NotificationPopover = (props: Props) => {
    const { notificationList, setNotificationList } =
        useContext(NotificationContext);

    let seenNotifications: Array<NotificationModel> = notificationList
        ? notificationList.filter((item: NotificationModel) => item.isViewed)
        : [];

    let unseenNotifications: Array<NotificationModel> = notificationList
        ? notificationList.filter((item: NotificationModel) => !item.isViewed)
        : [];

    const [activeTab, setActiveTab] = useState<number>(0);

    const addLinksToHref = (notification:string) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const replacedString = notification.replace(urlRegex, function (url) {
            let urlLabel = url.replace('https://','')
            return ' <a class="text-[#06D7A0]" href="' + url + '" target="_blank">' + urlLabel + '</a> ';
          });
          return replacedString;
    }

    const markAsRead = async (notificationId: string) => {
        try {
            const resp = await client.post(MARK_AS_READ_NOTIFICATION, {
                notification_id: [notificationId],
            });

            if (resp && resp.status === 200) {
                let tempList = notificationList.map(
                    (item: NotificationModel) => {
                        let temp: NotificationModel = {
                            ...item,
                        };

                        if (item.notificationId === notificationId) {
                            temp.isViewed = true;
                        }
                        return temp;
                    }
                );
                setNotificationList(tempList);
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong!');
        }
    };
    const markAllAsRead = async () => {
        let idsList = notificationList
            .filter((item: NotificationModel) => !item.isViewed)
            .map((item: NotificationModel) => item.notificationId);

        try {
            const resp = await client.post(MARK_AS_READ_NOTIFICATION, {
                notification_id: idsList,
            });

            if (resp && resp.status === 200) {
                let tempList = notificationList.map(
                    (item: NotificationModel) => {
                        let temp: NotificationModel = {
                            ...item,
                            isViewed: true,
                        };

                        return temp;
                    }
                );
                setNotificationList(tempList);
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong!');
        }
    };

    return (
        <div className="flex w-[60vw] lg:w-[40vw] flex-col gap-5 p-6">
            <div className="flex w-full">
                <button
                    className={`w-1/2 rounded-tl-2xl rounded-tr-2xl border-b-2 px-4 py-2 font-bold ${
                        activeTab === 0 ? 'border-[#06D7A0] text-[#06D7A0]' : ''
                    }`}
                    onClick={() => setActiveTab(0)}
                >
                    Latest
                </button>
                <button
                    className={`w-1/2 rounded-tl-2xl rounded-tr-2xl border-b-2 px-4 py-2 font-bold ${
                        activeTab === 1 ? 'border-[#06D7A0] text-[#06D7A0]' : ''
                    }`}
                    onClick={() => setActiveTab(1)}
                >
                    Archived
                </button>
            </div>
            {activeTab === 0 &&
                unseenNotifications &&
                unseenNotifications.map((notification: NotificationModel) => {
                    return (
                        <div className="flex" key={notification.notificationId}>
                            <div className="w-3/4">
                                <p className="text-xs text-[#06D7A0]">
                                    {notification.date}
                                </p>
                                <p className="text-sm" dangerouslySetInnerHTML={{__html: addLinksToHref(notification.notification)}}>
                                </p>
                            </div>
                            <button
                                onClick={() =>
                                    markAsRead(notification.notificationId)
                                }
                                className="w-1/4 text-xs text-[#06D7A0] hover:text-[#06d79fb4]"
                            >
                                Mark as read
                            </button>
                        </div>
                    );
                })}

            {activeTab === 0 &&
                unseenNotifications &&
                unseenNotifications.length > 1 && (
                    <button
                        onClick={markAllAsRead}
                        className="ml-auto w-fit text-sm text-[#06D7A0] hover:text-[#06d79fb4]"
                    >
                        Mark All as read
                    </button>
                )}

            {activeTab === 0 && unseenNotifications.length === 0 && (
                <p className="text-center">There is no new notifications</p>
            )}

            {activeTab === 1 &&
                seenNotifications &&
                seenNotifications.map(
                    (notification: NotificationModel, index: number) => {
                        if (index > 9) {
                            return null;
                        }
                        return (
                            <div
                                key={notification.notificationId}
                                className="flex"
                            >
                                <div className="w-full">
                                    <p className="text-xs text-[#06D7A0]">
                                        {notification.date}
                                    </p>
                                    <p className="text-sm">
                                        {notification.notification}
                                    </p>
                                </div>
                            </div>
                        );
                    }
                )}

            {activeTab === 1 && seenNotifications.length === 0 && (
                <p className="text-center">
                    There is no archived notifications
                </p>
            )}
        </div>
    );
};

export default NotificationPopover;
