import grid from 'src/assets/icons/grid-2-duotone.svg';
import market from 'src/assets/icons/MaterialSymbolsStoreOutlineRounded.svg';
import favoriteLinks from 'src/assets/icons/link-duotone.svg';
import orders from 'src/assets/icons/list-radio-duotone.svg';
import money from 'src/assets/icons/MaterialSymbolsMonetizationOnOutlineRounded.svg';

export interface RouteUrl {
    url:string,
    name:string,
    label:string,
    icon?:string
    showInMenu?:boolean,
    active?:boolean
}

export const protectedRoutesUrls:Array<RouteUrl> = [
    {
        url:'/',
        name:'dashboard',
        label:'Dashboard',
        icon:grid,
        showInMenu:true,
    },
    {
        url:'/marketplace',
        name:'marketplace',
        label:'Marketplace',
        icon:market,
        showInMenu:true
    },
    {
        url:'/orders',
        name:'orders',
        label:'Orders',
        icon:orders,
        showInMenu:true
    },
    {
        url:'/favorite-links',
        name:'favoriteLinks',
        label:'Favorite Links',
        icon:favoriteLinks,
        showInMenu:true
    }, {
        url:'/purchased-links',
        name:'purchasedLinks',
        label:'Purchased Links',
        icon:money,
        showInMenu:true
    },
    {
        url:'/checkout',
        name:'checkout',
        label:'Checkout',
        showInMenu:false
    },
]

export const publicRoutesUrls:Array<RouteUrl> = [
    {
        url:'/sign-in',
        name:'signIn',
        label:'Sign In',
    },
    {
        url:'/sign-up',
        name:'signUp',
        label:'Sign Up',
    },
    {
        url:'/forgot-password',
        name:'forgotPassword',
        label:'Forgot Password',
    },
    {
        url:'/confirm-email',
        name:'confirmEmail',
        label:'Confirm Email',
    },
]