import { createContext } from 'react';
import { Cart } from 'src/utils/cart/Cart.interface';

export type CartContent = {
    productList: Array<Cart>;
    setProductList: (productList: Array<Cart>) => void;
};

export const CartContext = createContext<CartContent>({
    productList: [],
    setProductList: () => {},
});
