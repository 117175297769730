import axios, { AxiosResponse } from 'axios';
import { getSession, logOut } from './auth';

export interface ApiResultRoute {
    limit?: number;
    next?: string;
    offset?: number;
    prev?: string;
    returned?: number;
    total?: number;
    url: string;
}

export interface ApiResult<T = unknown> {
    code: number;
    data: T;
    errors: unknown;
    message: string;
    status: string;
    success: boolean;
    _route: ApiResultRoute;
}

const SERVER_BASE = process.env.REACT_APP_API_LOCATION;
const baseURL = `${SERVER_BASE}/wp-json/`;
const headers = {
    'Content-Type': 'application/json',
};

export const client = axios.create({
    headers: headers,
    baseURL: baseURL,
});

function _requestInterceptor(config: any) {
    const session = getSession();
    if (config.headers) {
        config.headers['Authorization'] = `Bearer ${session?.accessToken}`;
    }
    return config;
}
client.interceptors.request.use(_requestInterceptor);

function _responseSuccessInterceptor(response: AxiosResponse<ApiResult>) {
    return response;
}

function _responseErrorInterceptor(error: any) {
    if (error.response && error.response.data) {
        const { code } = error.response.data;
        if (code === 'jwt_auth_invalid_token') {
            logOut();
            window.location.href =
                '/sign-in?referring=' + window.location.pathname;
            console.warn('[Token missing or invalid]');
        }
    }
    return error.response;
}

client.interceptors.response.use(
    _responseSuccessInterceptor,
    _responseErrorInterceptor
);
