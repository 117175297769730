import './App.css';
import Router from './components/router/Router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <div>
            <ToastContainer />
            <Router />
        </div>
    );
}

export default App;
