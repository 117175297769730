import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import Header from 'src/components/layout/Header';
import { CartContext } from 'src/context/CartContext';
import CheckoutForms from './CheckoutForms';
import CheckoutSummary from './CheckoutSummary';
import { Cart } from 'src/utils/cart/Cart.interface';
import {
    BillingInformationModel,
    LinkCheckoutModel,
} from './Checkout.interface';
import { getLinkAdditionalInfo } from 'src/utils/cart/additionalInfoController';
import { getBillingInfo } from 'src/utils/cart/billingInfoController';
import { client } from 'src/utils/client';
import { GET_ALL_COUNTRIES, GET_USER } from 'src/const/apiUrls';
import { User } from '../profile/Profile.interface';
import { toast } from 'react-toastify';
import { Countries } from 'src/interfaces/Country.interface';
import { SelectFieldData } from 'src/components/formField/SelectField.interface';

const Checkout = () => {
    const { productList } = useContext(CartContext);
    const navigate = useNavigate();
    const [subTotalPrice, setSubTotalPrice] = useState<number>(0);
    const [tax, setTax] = useState<number>(0);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [totalDiscount, setTotalDiscount] = useState<number>(0);
    const [user, setUser] = useState<User>();
    const [countries, setCountries] = useState<Array<SelectFieldData>>();
    const [billingInformation, setBillingInformation] =
        useState<BillingInformationModel>();
    const [linkCheckoutList, setLinkCheckoutList] = useState<
        Array<LinkCheckoutModel>
    >([]);

    useEffect(() => {
        try {
            const fetchData = async () => {
                const resp = await client.get(GET_USER);

                let user: User = resp.data;
                setUser(user);
                let billingInfo: BillingInformationModel | undefined =
                    getBillingInfo();

                if (billingInfo) {
                    setBillingInformation(billingInfo);
                } else {
                    setBillingInformation({
                        ...user,
                        businessName: user.companyLegalName,
                        isValid: false,
                    });
                }
            };
            fetchData();
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong!');
        }

        try {
            const fetchCountries = async () => {
                const respCountries = await client.get(GET_ALL_COUNTRIES);

                let tempCountries: Countries = respCountries.data;
                let keys: Array<string> = Object.keys(tempCountries);
                let selectData: Array<SelectFieldData> = keys.map(
                    (item: string) => {
                        let temp: SelectFieldData = {
                            label: tempCountries[item],
                            value: item,
                        };
                        return temp;
                    }
                );
                setCountries([{ label: 'All', value: '' }, ...selectData]);
            };
            fetchCountries();
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong!');
        }
    }, []);

    useEffect(() => {
        if (user && productList) {
            let tempTotal: number = 0;
            let discount: number = 0;
            let tempTax: number = 0;
            productList.forEach((item: Cart) => {
                let linkdAdditionalData = linkCheckoutList.find((linkData:LinkCheckoutModel)=>item.id === linkData.id);
                let additionalCost = 0;
                if(linkdAdditionalData){
                    let numberOfWords = linkdAdditionalData.articleWords ? Number(linkdAdditionalData.articleWords):500;
                    let defaultNumberOfWords = 500;
                    additionalCost = ((numberOfWords / 100) - (defaultNumberOfWords / 100)) * 15;
                }
                tempTotal += item.price + additionalCost;
            });

            setSubTotalPrice(tempTotal);

            if (user.discountAmount) {
                discount = (tempTotal * user.discountAmount) / 100;
                setTotalDiscount(discount);
                tempTotal = tempTotal - discount;
            }

            if (!user.taxFree && user.vatRate) {
                tempTax = (tempTotal * user.vatRate) / 100;
                setTax(tempTax);
                tempTotal = tempTotal + tempTax;
            }

            setTotalPrice(tempTotal);
        }
    }, [user, productList,linkCheckoutList]);

    useEffect(() => {
        let tempLinkList: Array<LinkCheckoutModel> = [];
        let formData: Array<LinkCheckoutModel> | undefined =
            getLinkAdditionalInfo();

        if (productList) {
            tempLinkList = productList.map((item: Cart) => {
                let tempLink: LinkCheckoutModel = {
                    link: item.name,
                    price: item.price,
                    id: item.id,
                    sku: item.sku,
                };

                if (formData) {
                    let tempForm = formData.find(
                        (form: LinkCheckoutModel) => form.id === item.id
                    );

                    if (tempForm) {
                        tempLink.anchorTag = tempForm.anchorTag
                            ? tempForm.anchorTag
                            : '';
                        tempLink.articleWords = tempForm.articleWords
                            ? tempForm.articleWords
                            : 0;
                        tempLink.anchorTag = tempForm.anchorTag
                            ? tempForm.anchorTag
                            : '';
                        tempLink.topic = tempForm.topic ? tempForm.topic : '';
                        tempLink.urlSource = tempForm.urlSource
                            ? tempForm.urlSource
                            : '';
                        tempLink.placement = tempForm.placement
                            ? tempForm.placement
                            : 'anywhere';
                        tempLink.trustLink = tempForm.trustLink
                            ? tempForm.trustLink
                            : 'true';
                    }
                }
                return tempLink;
            });

            setLinkCheckoutList(tempLinkList);
        }
    }, [productList]);

    return (
        <div className="flex flex-col gap-10">
            <Header title="Checkout"></Header>
            <div>
                {productList.length === 0 && (
                    <div className="flex flex-col gap-6 rounded-3xl bg-[#FFF] px-6 py-4">
                        <p className="text-2xl font-normal text-[#1F1F1F]">
                            You don't have any items in your cart.
                        </p>
                        <PrimaryButton
                            type="button"
                            label="Continue shopping"
                            className="w-fit rounded-xl"
                            onClick={() => navigate('/marketplace')}
                        />
                    </div>
                )}

                {linkCheckoutList && linkCheckoutList.length > 0 && (
                    <div className="flex flex-col gap-8 lg:flex-row">
                        <CheckoutForms
                            billingInformation={billingInformation}
                            setBillingInformation={setBillingInformation}
                            linkCheckoutList={linkCheckoutList}
                            setLinkCheckoutList={setLinkCheckoutList}
                            countries={countries}
                            className="flex w-full flex-col rounded-2xl bg-[#FFF] px-6 py-8 lg:w-2/3"
                        />
                        <CheckoutSummary
                            totalPrice={totalPrice}
                            subTotalPrice={subTotalPrice}
                            tax={tax}
                            totalDiscount={totalDiscount}
                            productList={productList}
                            linkCheckoutList={linkCheckoutList}
                            user={user}
                            setSubTotalPrice={setSubTotalPrice}
                            className="sticky top-5 flex h-fit max-h-[80vh] flex-col overflow-y-auto rounded-2xl bg-[#FFF] px-3 py-7 lg:w-1/3"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Checkout;
