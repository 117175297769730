import FormField from 'src/components/formField/FormField';
import { BillingInformationModel, StepModel } from '../Checkout.interface';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { saveBillingInfo } from 'src/utils/cart/billingInfoController';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import SelectField from 'src/components/formField/SelectField';
import { SelectFieldData } from 'src/components/formField/SelectField.interface';

type Props = {
    billingInformation?: BillingInformationModel;
    setBillingInformation: React.Dispatch<
        React.SetStateAction<BillingInformationModel | undefined>
    >;
    steps: Array<StepModel>;
    activeStep: number;
    navigateTo: (step: number, currentStepValid?: boolean) => void;
    onStepChange: (step: number) => void;
    setCurrentStepValid: (valid: boolean) => void;
    countries: Array<SelectFieldData> | undefined;
};

const BillingInformationStep = ({
    billingInformation,
    setBillingInformation,
    steps,
    activeStep,
    navigateTo,
    onStepChange,
    setCurrentStepValid,
    countries,
}: Props) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<BillingInformationModel>();

    useEffect(() => {
        if (billingInformation) {
            reset(billingInformation);
        }
    }, [reset, billingInformation]);

    const onSubmit = (data: BillingInformationModel) => {
        setBillingInformation({
            ...data,
            isValid: true,
        });
        saveBillingInfo({
            ...data,
            isValid: true,
        });
        setCurrentStepValid(true);
        navigateTo(activeStep + 1, true);
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-6 p-6"
        >
            <div className="flex">
                <FormField
                    label="Invoice email"
                    name="email"
                    type="text"
                    placeholder="Enter the Email"
                    register={register}
                    className="w-full"
                    required
                    errors={errors}
                ></FormField>
            </div>
            <div className="flex flex-col justify-between gap-3 sm:flex-row">
                <FormField
                    label="Business name"
                    name="businessName"
                    type="text"
                    placeholder="Enter the Business name"
                    register={register}
                    required
                    className="w-full sm:w-1/2"
                    errors={errors}
                ></FormField>
                <FormField
                    label="VAT number (Prefix with country code)"
                    name="vatNumber"
                    type="text"
                    placeholder="Enter the VAT number"
                    register={register}
                    className="w-full sm:w-1/2"
                    errors={errors}
                ></FormField>
            </div>
            <div className="flex flex-col justify-between gap-3 sm:flex-row">
                <FormField
                    label="Address"
                    name="address"
                    type="text"
                    placeholder="Enter the Address"
                    register={register}
                    required
                    className="w-full sm:w-1/2"
                    errors={errors}
                ></FormField>
                <FormField
                    label="Postal Code"
                    name="postalCode"
                    type="text"
                    placeholder="Enter the Postal Code"
                    register={register}
                    className="w-full sm:w-1/2"
                    errors={errors}
                ></FormField>
            </div>
            <div className="flex flex-col justify-between gap-3 sm:flex-row">
                <SelectField
                    className="w-full sm:w-1/2"
                    title="Country"
                    name="country"
                    required
                    register={register}
                    items={countries ? countries : []}
                    errors={errors}
                />
                <FormField
                    label="City"
                    name="city"
                    type="text"
                    placeholder="Enter the City"
                    register={register}
                    className="w-full sm:w-1/2"
                    errors={errors}
                    required
                ></FormField>
            </div>
            <div className="mt-auto flex items-center justify-between pt-5">
                {activeStep !== 1 && (
                    <button
                        className="mr-auto text-base font-semibold text-[#06D7A0] hover:text-[#06d79fb4]"
                        onClick={() => onStepChange(activeStep - 1)}
                    >
                        Back
                    </button>
                )}
                {activeStep !== steps.length && (
                    <PrimaryButton
                        type="submit"
                        className="ml-auto"
                        label="Next"
                    />
                )}
            </div>
        </form>
    );
};

export default BillingInformationStep;
