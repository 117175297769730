import { DataGrid } from '@mui/x-data-grid';
import Header from 'src/components/layout/Header';
import TableHeader from 'src/components/table/TableHeader';
import { columns } from './TableColumns';
import { useEffect, useState } from 'react';
import { client } from 'src/utils/client';
import { GET_ALL_ORDERS } from 'src/const/apiUrls';
import { Order } from './Orders.interface';
import { CircularProgress, LinearProgress } from '@mui/material';
import { toast } from 'react-toastify';

const Orders = () => {
    const [orders, setOrders] = useState<Array<Order>>([]);
    const [filteredOrders, setFilteredOrders] = useState<Array<Order>>([]);
    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let resp = await client.get(GET_ALL_ORDERS);
                if (resp && resp.data) {
                    let tempOrders: Array<Order> = resp.data;
                    setOrders(tempOrders);
                    setFilteredOrders(tempOrders);
                    setLoading(false);
                }
            } catch (error) {
                console.log(error);
                toast.error('Something went wrong!');
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (search) {
            let tempFilteredOrders = orders.filter((item: Order) =>
                item.orderId.toString().trim().includes(search.trim())
            );
            setFilteredOrders(tempFilteredOrders);
        } else {
            setFilteredOrders(orders);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return (
        <div className="flex flex-col gap-8">
            <Header title="Orders"></Header>
            <div>
                <TableHeader
                    showSearch={true}
                    setSearch={setSearch}
                    totalRows={orders.length}
                    searchTotalRows={
                        search ? filteredOrders.length : orders.length
                    }
                ></TableHeader>
                <div
                    style={{ width: '100%' }}
                    className="d-flex w-full items-center justify-center"
                >
                    {loading && (
                        <CircularProgress
                            style={{
                                position: 'relative',
                                top: '30vh',
                                left: '50%',
                                color: '#06D7A0',
                            }}
                        />
                    )}
                    {!loading &&
                        filteredOrders &&
                        filteredOrders.length > 0 && (
                            <DataGrid
                                style={{ overflowX: 'hidden' }}
                                sx={{
                                    background: '#FFF',
                                    borderBottomRightRadius: '20px',
                                    borderBottomLeftRadius: '20px',
                                    borderTopLeftRadius: '0px',
                                    borderTopRightRadius: '0px',
                                    border: 'none',
                                    overflowX: 'scroll',
                                }}
                                slots={{
                                    loadingOverlay: LinearProgress,
                                }}
                                getRowId={(row: any) => row.orderId}
                                rows={
                                    filteredOrders.length > 0
                                        ? filteredOrders
                                        : orders
                                }
                                columns={columns}
                                disableRowSelectionOnClick
                                disableColumnMenu
                                loading={loading}
                                getRowClassName={(params: any) => {
                                    let textClass =
                                        'text-sm text-[#1B1C19] border-0 ';
                                    let backPrimaryClass = 'bg-[#FFFFFF] ';
                                    let backSecondaryClass = 'bg-[#FAFAFA] ';
                                    return params.indexRelativeToCurrentPage %
                                        2 ===
                                        0
                                        ? textClass + backPrimaryClass
                                        : textClass + backSecondaryClass;
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            page: 0,
                                            pageSize: 25,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5, 10, 25]}
                            />
                        )}
                    {filteredOrders &&
                        filteredOrders.length === 0 &&
                        !loading && (
                            <div className="flex w-full justify-center bg-[#fff] p-5 font-bold">
                                No data found
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
};

export default Orders;
