import { useForm } from 'react-hook-form';
import { UpdateUserDTO } from './Authentication.interface';
import logo from 'src/assets/images/black-logo.svg';
import FormField from 'src/components/formField/FormField';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import { client } from 'src/utils/client';
import {
    GET_ALL_CATEGORIES,
    GET_ALL_COUNTRIES,
    UPDATE_USER,
} from 'src/const/apiUrls';
import { useNavigate } from 'react-router-dom';
import { setValidUser } from 'src/utils/auth';
import { useEffect, useState } from 'react';
import { Countries } from 'src/interfaces/Country.interface';
import { Category } from 'src/interfaces/Category.interface';
import SelectField from 'src/components/formField/SelectField';
import { SelectFieldData } from 'src/components/formField/SelectField.interface';
import Multiselect from 'multiselect-react-dropdown';
import { toast } from 'react-toastify';

const CreateProfile = () => {
    const navigate = useNavigate();
    const [countries, setCountries] = useState<Array<SelectFieldData>>([]);
    const [categories, setCategories] = useState<Array<Category>>([]);
    const [selectedCategories, setSelectedCategories] = useState<
        Array<Category>
    >([]);
    const [errorCategory, setErrorCategory] = useState<string>('');
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<UpdateUserDTO>();

    const onSubmit = async (data: UpdateUserDTO) => {
        if (!selectedCategories || selectedCategories.length === 0) {
            setErrorCategory('Required field');
            return;
        } else {
            data.favorite_categories = selectedCategories;
            setErrorCategory('');
        }
        try {
            let resp = await client.post(UPDATE_USER, {
                data: data,
            });
            if (resp.data && resp.data.success) {
                setValidUser(true);
                navigate('/');
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong!');
        }
    };

    useEffect(() => {
        try {
            const fetchData = async () => {
                let resp = await client.get(GET_ALL_CATEGORIES);
                if (resp && resp.data) {
                    setCategories(resp.data);
                }
            };
            fetchData();
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong!');
        }
    }, []);

    useEffect(() => {
        try {
            const fetchData = async () => {
                let resp = await client.get(GET_ALL_COUNTRIES);
                if (resp && resp.data) {
                    let tempCountries: Countries = resp.data;
                    let countryKeys = Object.keys(tempCountries);
                    let selectCountries: Array<SelectFieldData> =
                        countryKeys.map((item: string) => {
                            return {
                                label: tempCountries[`${item}`],
                                value: item,
                            };
                        });
                    setCountries([
                        { value: '', label: '' },
                        ...selectCountries,
                    ]);
                }
            };
            fetchData();
        } catch (error) {
            console.log(error);
            toast.error('Something went error!');
        }
    }, []);

    return (
        <div className="flex h-screen flex-col">
            <div className="flex w-full flex-col bg-[#DEFD71] ">
                <img className="h-13 m-3 w-1/12" src={logo} alt="d_tre" />
                {/* <img
                    className="w-full md:w-3/4 lg:w-2/3 xl:w-1/2"
                    src={backImg}
                    alt="login"
                /> */}
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-14 py-5">
                <h1 className="text-4xl">Create profile</h1>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex w-full flex-col gap-5 px-3 md:w-4/5 md:px-0 lg:w-2/3 xl:w-1/2"
                >
                    <div className="flex w-full flex-col gap-3 sm:flex-row">
                        <FormField
                            name="company_legal_name"
                            register={register}
                            type="text"
                            label="Company legal name"
                            placeholder="Enter your company legal name"
                            required
                            errors={errors}
                            className="w-full"
                        />

                        <FormField
                            name="display_name"
                            register={register}
                            type="text"
                            label="Company display name"
                            placeholder="Enter your company display name"
                            required
                            errors={errors}
                            className="w-full"
                        />
                    </div>
                    <div className="flex w-full flex-col gap-3 sm:flex-row">
                        <FormField
                            name="company_website"
                            register={register}
                            type="text"
                            label="Company website"
                            placeholder="Enter your company website"
                            required
                            errors={errors}
                            className="w-full"
                        />

                        <FormField
                            name="email"
                            register={register}
                            type="email"
                            label="E-mail"
                            placeholder="Enter your e-mail"
                            required
                            errors={errors}
                            className="w-full"
                        />
                    </div>
                    <div className="flex w-full flex-col gap-3 sm:flex-row">
                        <FormField
                            name="address"
                            register={register}
                            type="text"
                            label="Address"
                            placeholder="Enter your address"
                            required
                            errors={errors}
                            className="w-full"
                        />

                        <FormField
                            name="postal_code"
                            register={register}
                            type="text"
                            label="Postal code"
                            placeholder="Enter your postal code"
                            required
                            errors={errors}
                            className="w-full"
                        />
                    </div>

                    <div className="flex w-full flex-col gap-3 sm:flex-row">
                        <FormField
                            name="city"
                            register={register}
                            type="text"
                            label="City"
                            placeholder="Enter your city"
                            required
                            errors={errors}
                            className="w-full"
                        />

                        <SelectField
                            className="w-full"
                            title="Country"
                            name="country"
                            required
                            register={register}
                            items={countries}
                            errors={errors}
                        />
                    </div>

                    <div className="flex w-full flex-col gap-3 sm:flex-row">
                        <FormField
                            name="phone_number"
                            register={register}
                            type="text"
                            label="Phone number"
                            placeholder="Enter your phone number"
                            required
                            errors={errors}
                            className="w-full"
                        />
                        <FormField
                            name="vat_number"
                            register={register}
                            type="text"
                            label="VAT ID number"
                            placeholder="Enter your VAT ID number"
                            errors={errors}
                            className="w-full"
                        />
                    </div>
                    <div className="flex w-full flex-col gap-3 sm:flex-row">
                        <FormField
                            name="focused_domain"
                            register={register}
                            type="text"
                            label="Domain in focus"
                            placeholder="Enter your domain in focus"
                            required
                            errors={errors}
                            className="w-full"
                        />
                    </div>
                    <div className={'flex w-full flex-col gap-2'}>
                        <label className="text-sm font-bold text-[#1B1C19]">
                            Category
                            <span
                                className={'text-sm font-bold text-[#FF7E66]'}
                            >
                                {' '}
                                *
                            </span>
                            <span className="pl-2 text-sm font-medium">
                                {' '}
                                (max 5 categories)
                            </span>
                        </label>
                        <Multiselect
                            className="focus:border-1 w-full rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-[6px] text-[#708191] focus:border-[#06D7A0]"
                            options={categories}
                            selectedValues={selectedCategories}
                            displayValue="name"
                            selectionLimit={5}
                            placeholder="Category"
                            onSelect={(selectedList, selectedItem) => {
                                let temp: Array<Category> = [
                                    ...selectedCategories,
                                ];
                                temp.push(selectedItem);
                                setSelectedCategories(temp);
                            }}
                            onRemove={(selectedList, selectedItem) => {
                                let temp: Array<Category> =
                                    selectedCategories.filter(
                                        (item: Category) =>
                                            item.id !== selectedItem.id
                                    );
                                setSelectedCategories(temp);
                            }}
                            showArrow
                            showCheckbox
                        />
                        <span className="text-red-700">
                            {errorCategory && errorCategory}
                        </span>
                    </div>
                    <div className="flex w-full flex-col gap-3 sm:flex-row">
                        <label
                            htmlFor="tax-free"
                            className="flex items-center gap-2 text-base font-bold text-[#1B1C19]"
                        >
                            <input
                                className="h-5 w-5"
                                type="checkbox"
                                id="tax-free"
                                {...register('tax_free')}
                            />
                            I am free from VAT
                        </label>
                    </div>

                    {/* <div className="flex items-center gap-2">
                        <input
                            className="h-5 w-5 rounded-3xl border border-solid border-[#DDD] bg-[#FFF] hover:cursor-pointer"
                            type="checkbox"
                        />
                        <label className="text-sm font-normal">
                            I accept{' '}
                            <Link to="/" className="underline">
                                Terms and Conditions
                            </Link>
                        </label>
                    </div> */}
                    <PrimaryButton
                        type={'submit'}
                        label="Submit"
                        className="mx-auto w-fit"
                    />
                    {/* <div className="flex items-center  ">
                        <p className="text-sm font-normal">
                            Already have an Account?
                        </p>
                        <Link
                            to={'/sign-in'}
                            className="text-bold pl-2 text-sm font-bold text-[#06D7A0] hover:text-[#06d79f8e]"
                        >
                            Sign in
                        </Link>
                    </div> */}
                </form>
            </div>
        </div>
    );
};

export default CreateProfile;
