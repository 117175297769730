import { useForm } from 'react-hook-form';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import FormField from 'src/components/formField/FormField';
import { RecoverPasswordData } from './Authentication.interface';
import logo from 'src/assets/images/black-logo.svg';
import backImg from 'src/assets/images/password-bro.svg';

const ForgotPassword = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RecoverPasswordData>();

    const onSubmit = () => {};

    return (
        <div className="flex h-screen w-screen">
            <div className="flex w-1/2 flex-col items-center justify-center bg-[#DEFD71]">
                <img
                    className="w-full p-8 sm:p-12 md:w-1/2 md:p-0 lg:w-1/2 xl:w-1/3"
                    src={logo}
                    alt="d_tre"
                />
                <img
                    className="w-full md:w-3/4 lg:w-2/3 xl:w-1/2"
                    src={backImg}
                    alt="login"
                />
            </div>
            <div className="flex w-1/2 flex-col items-center justify-center gap-7">
                <h1 className="w-full text-center text-3xl lg:w-3/4 lg:text-3xl">
                    Forgot your password?
                </h1>
                <p className="w-full px-2 text-center text-sm lg:w-1/2 lg:px-0">
                    Please enter the email address associated with your account
                    and We will email you a link to reset your password.
                </p>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex w-full flex-col gap-5 px-3 md:w-4/5 md:px-0 lg:w-2/3 xl:w-1/2"
                >
                    <FormField
                        name="email"
                        register={register}
                        type="email"
                        label="E-mail"
                        placeholder="Enter your e-mail"
                        required
                        errors={errors}
                    />

                    <PrimaryButton type={'submit'} label="Sign in to account" />
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
