import { Dialog, DialogContent } from '@mui/material';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import SecondaryButton from 'src/components/buttons/SecondaryButton';
import FormField from 'src/components/formField/FormField';
import { UPDATE_ORDER_STATUS } from 'src/const/apiUrls';
import { client } from 'src/utils/client';
import { ApproveResourceDTO } from './Orders.interface';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    orderId: string;
};

const ApproveResourceDialog = ({ isOpen, onClose, orderId }: Props) => {
    const { register, handleSubmit } = useForm<any>();

    const handleApproveHandler = async (data: any) => {
        try {
            let payload: ApproveResourceDTO = {
                order_id: orderId,
                status: 'approved',
                order_note: data.order_note ? data.order_note : '',
            };
            const resp = await client.post(UPDATE_ORDER_STATUS, payload);
            if (resp && resp.data && resp.data.success) {
                toast.success('You have approved order resources.');
                onClose();
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong!');
        }
    };

    const handleRejectHandler = async (data: any) => {
        try {
            let payload: ApproveResourceDTO = {
                order_id: orderId,
                status: 'rejected',
                order_note: data.order_note ? data.order_note : '',
            };
            const resp = await client.post(UPDATE_ORDER_STATUS, payload);
            if (resp && resp.data && resp.data.success) {
                toast.warn('You have rejected order resources.');
                onClose();
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong!');
        }
    };
    return (
        <Dialog onClose={onClose} open={isOpen} fullWidth className="">
            <form>
                <DialogContent className="flex flex-col gap-5">
                    <h1 className="text-xl font-bold">
                        Approve order resource
                    </h1>
                    <FormField
                        label="Note"
                        type="textArea"
                        name="order_note"
                        register={register}
                    />
                    <div className="flex items-center gap-5">
                        <SecondaryButton
                            type="button"
                            label="Cancel"
                            className="mr-auto"
                            onClick={() => {
                                onClose();
                            }}
                        />
                        <PrimaryButton
                            className="bg-[#ff6868] hover:bg-[#ff8585]"
                            onClick={handleSubmit((data) =>
                                handleRejectHandler(data)
                            )}
                            label="Reject"
                        />
                        <PrimaryButton
                            type="submit"
                            label="Approve"
                            onClick={handleSubmit((data) =>
                                handleApproveHandler(data)
                            )}
                        />
                    </div>
                </DialogContent>
            </form>
        </Dialog>
    );
};

export default ApproveResourceDialog;
