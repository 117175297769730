import {
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
} from '@mui/x-data-grid';
import { Order } from './Orders.interface';
import StatusComponent from './StatusComponent';
import { Link } from 'react-router-dom';

export const columns: GridColDef[] = [
    {
        field: 'orderId',
        headerName: 'Order #',
        flex: 0.5,
        minWidth: 90,
        headerClassName: 'text-xs text-[#708191]',
    },
    {
        field: 'date',
        type: 'date',
        flex: 1,
        minWidth: 120,
        valueFormatter: (params: GridValueFormatterParams<Date>) => {
            if (params.value == null) {
                return '';
            }
            return `${params.value.toLocaleString('de')}`;
        },
        headerName: 'Date ordered',
        headerClassName: 'text-xs text-[#708191]',
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        minWidth: 110,
        headerClassName: 'text-xs text-[#708191]',
        renderCell: (params: GridRenderCellParams<Order>) => {
            return <StatusComponent status={params.value}></StatusComponent>;
        },
    },
    {
        field: 'amount',
        headerName: 'Items',
        flex: 1,
        minWidth: 80,
        headerClassName: 'text-xs text-[#708191]',
    },
    {
        field: 'price',
        headerName: 'Amount',
        flex: 1,
        minWidth: 100,
        headerClassName: 'text-xs text-[#708191]',
        valueFormatter: (params: GridValueFormatterParams<Date>) => {
            if (params.value == null) {
                return '';
            }
            return `€${params.value}`;
        },
    },
    {
        field: 'action',
        headerName: '',
        sortable: false,
        flex: 1,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<Order>) => {
            return (
                <Link
                    to={'/order/' + params.row.orderId}
                    className="ml-auto text-base text-[#06D7A0] hover:text-[#06d79fb4] "
                >
                    View order
                </Link>
            );
        },
    },
];
