import {
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
} from '@mui/x-data-grid';
import TableBuyButton from '../../../checkout/TableBuyButton';
import { Link } from '../../../marketplace/Marketplace.interface';

export const columns: GridColDef[] = [
    {
        field: 'price',
        headerName: 'Price',
        headerClassName: 'text-xs text-[#708191] ',
        flex: 0.5,
        minWidth: 80,
        valueFormatter: (params: GridValueFormatterParams<Link>) => {
            if (params.value == null) {
                return '';
            }
            return `€${params.value}`;
        },
    },
    {
        field: 'title',
        headerName: 'Title',
        headerClassName: 'text-xs text-[#708191]',
        flex: 1,
        minWidth: 150,
    },
    {
        field: 'language',
        headerName: 'Language',
        headerClassName: 'text-xs text-[#708191]',
        flex: 1,
        minWidth: 110,
    },
    {
        field: 'refDomains',
        headerName: 'Ref domains',
        headerClassName: 'text-xs text-[#708191]',
        flex: 1,
        minWidth: 120,
    },
    {
        field: 'action',
        headerName: '',
        sortable: false,
        flex: 0.5,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<Link>) => {
            return <TableBuyButton params={params} />;
        },
    },
];
