import { SelectFieldData } from "src/components/formField/SelectField.interface";

export const articleWords:Array<SelectFieldData> = [ 
    {
        label:'500',
        value:500
    },
    {
        label:'600',
        value:600
    },
    {
        label:'700',
        value:700
    },
    {
        label:'800',
        value:800
    },
    {
        label:'900',
        value:900
    },
    {
        label:'1000',
        value:1000
    },
    {
        label:'1100',
        value:1100
    },
    {
        label:'1200',
        value:1200
    },
    {
        label:'1300',
        value:1300
    },
    {
        label:'1400',
        value:1400
    },
    {
        label:'1500',
        value:1500
    },
    {
        label:'No content needed',
        value:0
    }
]