import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';
import Home from '../../pages/home/Home';
import Login from '../../pages/authentication/Login';
import NotFound from '../../pages/NotFound';
import Marketplace from '../../pages/marketplace/Marketplace';
import Orders from '../../pages/orders/Orders';
import Checkout from '../../pages/checkout/Checkout';
import ForgotPassword from 'src/pages/authentication/ForgotPassword';
import ConfirmEmail from 'src/pages/authentication/ConfirmEmail';
import Confirmation from 'src/pages/checkout/Confirmation';
import CreateProfile from 'src/pages/authentication/CreateProfile';
import Profile from 'src/pages/profile/Profile';
import OrderDetail from 'src/pages/orders/OrderDetail';
import FavoriteLinks from 'src/pages/favoriteLinks/FavoriteLinks';
import PurchasedLinks from 'src/pages/purchasedLinks/PurchasedLinks';

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<ProtectedRoutes />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/marketplace" element={<Marketplace />} />
                    <Route path="/orders" element={<Orders />} />
                    <Route path="/order/:id" element={<OrderDetail />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/confirmation" element={<Confirmation />} />
                    <Route path="/favorite-links" element={<FavoriteLinks />} />
                    <Route path="/purchased-links" element={<PurchasedLinks />} />
                </Route>
                <Route path="/sign-in" element={<Login />} />
                <Route path="/create-profile" element={<CreateProfile />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/confirm-email" element={<ConfirmEmail />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
