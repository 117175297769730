import { StepModel } from '../Checkout.interface';
import check from 'src/assets/icons/check.svg';

type Props = {
    model: StepModel;
};

const Step = ({ model }: Props) => {
    let activeClassName =
        model.isActive && !model.isFinished
            ? 'bg-[#DEFD71] border-0'
            : 'border bg-transparent border-[#1B1C19]';

    let finishedComponent = <img className="h-5 w-5" src={check} alt="Check" />;

    return (
        <div className="flex w-fit gap-2">
            {model.isFinished && (
                <div
                    className={
                        'flex h-10 w-10 items-center justify-center rounded-full border-0 bg-[#DEFD71] '
                    }
                >
                    {model.isFinished && finishedComponent}
                </div>
            )}

            {!model.isFinished && (
                <div
                    className={
                        'flex h-10 w-10 items-center justify-center rounded-full ' +
                        activeClassName
                    }
                >
                    {!model.isFinished && model.id}
                </div>
            )}
            <div className="flex w-fit flex-col justify-center">
                <p className="hidden text-sm font-bold text-[#1B1C19] sm:flex">
                    {model.title}
                </p>
                <p className="hidden text-xs text-[#708191] md:flex lg:hidden xl:flex">
                    {model.subtitle}
                </p>
            </div>
        </div>
    );
};

export default Step;
