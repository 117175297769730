import { Cart } from './Cart.interface';

let key: string = 'cart';


export const addToCart = (product:Cart) => {
    if (typeof window !== 'undefined') {
        let tempProductList: Array<Cart>;
        let storageProductList: Array<Cart> | undefined = getCart();

        if (storageProductList) {
            let searchedIndex = storageProductList.findIndex(
                (item: Cart) => item.id === product.id
            );
            if (searchedIndex !== -1) {
                tempProductList = [...storageProductList];
                window.localStorage.setItem(
                    key,
                    JSON.stringify(storageProductList)
                );
                return;
            }
            tempProductList = [
                ...storageProductList,
                product
            ];
            window.localStorage.setItem(key, JSON.stringify(tempProductList));
        } else {
            tempProductList = [
               product
            ];
            window.localStorage.setItem(key, JSON.stringify(tempProductList));
        }
    }
};

export const getCart = (): Array<Cart> => {
    if (typeof window !== 'undefined') {
        let localStorageString = window.localStorage.getItem(key);
        if (localStorageString) {
            let storageProductList:Array<any> =
                JSON.parse(localStorageString);
            let tempProductList: Array<Cart> = storageProductList.map((item:any)=>{
                let tempPrice = typeof item.price === 'string'? Number.parseFloat(item.price) : item.price;
                return {
                    ...item,
                    price:tempPrice
                }
            })
            return tempProductList;
        }
    }
    return [];
};

export const getCartNumberSize = () => {
    const cart = getCart();
    if (cart) {
        return cart.length;
    }
    return 0;
};

export const updateCart = (productList:Array<Cart>)=>{
    if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(productList));
    }
}

export const removeFromCart = (productId: string) => {
    if (typeof window !== 'undefined') {
        let storageProductList: Array<Cart> | undefined = getCart();
        let tempProductList: Array<Cart> = storageProductList
            ? storageProductList.filter((item: Cart) => item.id !== productId)
            : [];
        window.localStorage.setItem(key, JSON.stringify(tempProductList));
    }
};

export const removeAllProducts = () =>{
    if (typeof window !== 'undefined') {
        window.localStorage.removeItem(key);
    }
}
