import { CircularProgress, LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from 'react-query';
import ordersImg from 'src/assets/icons/orders.svg';
import TableHeader from 'src/components/table/TableHeader';
import { GET_ALL_ORDERS } from 'src/const/apiUrls';
import { Order } from 'src/pages/orders/Orders.interface';
import { client } from 'src/utils/client';
import { orderColumns } from './OrdersColumns';
import { toast } from 'react-toastify';

type Props = {
    className?: string;
};

const OrdersTable = ({ className }: Props) => {
    const fetchOrders = async () => {
        try {
            let resp = await client.get(GET_ALL_ORDERS);
            if (resp && resp.data) {
                let tempOrders: Array<Order> = resp.data;
                if (tempOrders.length < 5) {
                    return tempOrders;
                }
                return tempOrders.slice(0, 5);
            }
            return null;
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong!');
        }
    };
    const { data, error, isError, isLoading } = useQuery('orders', fetchOrders);

    if (isLoading) {
        return (
            <div className={'flex flex-col ' + className}>
                <TableHeader
                    icon={<img src={ordersImg} alt="Orders" />}
                    title="Orders"
                ></TableHeader>
                <div className="d-flex h-full w-full items-center justify-center">
                    <CircularProgress
                        style={{
                            position: 'relative',
                            top: '25vh',
                            left: '50%',
                            color: '#06D7A0',
                        }}
                    />
                </div>
            </div>
        );
    }

    if (error instanceof Error && isError) {
        return <div>An error occurred: {error.message}</div>;
    }

    return (
        <div className={'flex flex-col ' + className}>
            <TableHeader
                icon={<img src={ordersImg} alt="Orders" />}
                title="Orders"
            ></TableHeader>
            <div className={'flex h-full w-full'}>
                {data && (
                    <DataGrid
                        style={{ overflowX: 'hidden' }}
                        sx={{
                            background: '#FFF',
                            borderBottomRightRadius: '20px',
                            borderBottomLeftRadius: '20px',
                            borderTopLeftRadius: '0px',
                            borderTopRightRadius: '0px',
                            border: 'none',
                            overflowX: 'scroll',
                        }}
                        slots={{
                            loadingOverlay: LinearProgress,
                        }}
                        getRowId={(row: Order) => row.orderId}
                        rows={data}
                        columns={orderColumns}
                        disableRowSelectionOnClick
                        disableColumnMenu
                        loading={isLoading}
                        getRowClassName={(params: any) => {
                            let textClass = 'text-sm text-[#1B1C19] border-0 ';
                            let backPrimaryClass = 'bg-[#FFFFFF] ';
                            let backSecondaryClass = 'bg-[#FAFAFA] ';
                            return params.indexRelativeToCurrentPage % 2 === 0
                                ? textClass + backPrimaryClass
                                : textClass + backSecondaryClass;
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    page: 0,
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                    />
                )}
            </div>
        </div>
    );
};

export default OrdersTable;
