import PrimaryButton from 'src/components/buttons/PrimaryButton';
import { LinkCheckoutModel, StepModel } from '../Checkout.interface';
import cross from 'src/assets/icons/cross-grey.svg';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FormField from 'src/components/formField/FormField';
import { Cart } from 'src/utils/cart/Cart.interface';
import { CartContext } from 'src/context/CartContext';
import { removeFromCart } from 'src/utils/cart/cartController';
import { saveLinkAdditionalInfo } from 'src/utils/cart/additionalInfoController';
import SelectField from 'src/components/formField/SelectField';
import { articleWords } from 'src/const/articleWords';

type Props = {
    linkCheckoutList: Array<LinkCheckoutModel>;
    setLinkCheckoutList: React.Dispatch<
        React.SetStateAction<Array<LinkCheckoutModel>>
    >;
    setCurrentStepValid: (valid: boolean) => void;
    steps: Array<StepModel>;
    activeStep: number;
    navigateTo: (step: number, currentStepValid: boolean) => void;
};

const LinkAdditionalInfoStep = ({
    linkCheckoutList,
    setLinkCheckoutList,
    setCurrentStepValid,
    steps,
    activeStep,
    navigateTo,
}: Props) => {
    const navigate = useNavigate();
    const { productList, setProductList } = useContext(CartContext);
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm<any>({
        defaultValues: async () => {
            let tempData: any = {};
            linkCheckoutList.forEach((item: LinkCheckoutModel) => {
                tempData[`${item.id}-anchorTag`] = item.anchorTag;
                tempData[`${item.id}-articleWords`] = item.articleWords;
                tempData[`${item.id}-topic`] = item.topic;
                tempData[`${item.id}-urlSource`] = item.urlSource;
                tempData[`${item.id}-notes`] = item.notes;
                tempData[`${item.id}-trustLink`] = item.trustLink
                    ? item.trustLink
                    : 'true';
                tempData[`${item.id}-placement`] = item.placement
                    ? item.placement
                    : 'anywhere';
            });
            return tempData;
        },
    });

    const onSubmit = (data: any) => {
        let tempLinkCheckoutList: Array<LinkCheckoutModel> = []
        tempLinkCheckoutList = linkCheckoutList.map(
            (item: LinkCheckoutModel) => {
                let linkId = item.id ? item.id : '';
                let dataKeys: Array<string> = Object.keys(data);

                let filteredKeys: Array<string> = dataKeys.filter(
                    (key: string) => {
                        return key.includes(linkId);
                    }
                );

                let newKeys: Array<string> = filteredKeys.map((key: string) => {
                    return key.replace(linkId + '-', '');
                });

                let tempLink: any = {
                    ...item,
                };
                newKeys.forEach((key: string) => {
                    tempLink[key] = data[`${linkId}-${key}`];
                });

                let link: LinkCheckoutModel = {
                    ...tempLink,
                };
                return link;
            }
        );
        setLinkCheckoutList(tempLinkCheckoutList);
        saveLinkAdditionalInfo(tempLinkCheckoutList);
        setCurrentStepValid(true);
        navigateTo(activeStep + 1, true);
    };


    const removeProduct = (id?: string) => {
        let tempProductList: Array<Cart> = productList.filter(
            (item: Cart) => item.id !== id
        );
        let tempLinkCheckoutList: Array<LinkCheckoutModel> =
            linkCheckoutList.filter(
                (item: LinkCheckoutModel) => item.id !== id
            );

        removeFromCart(id ? id : '');
        setProductList(tempProductList);
        saveLinkAdditionalInfo(tempLinkCheckoutList);
        setLinkCheckoutList(tempLinkCheckoutList);
    };

    const articleWordsOnchangeHandler = (event:any,item:LinkCheckoutModel) =>{
        setValue(event.target.name,event.target.value);
        let productId = event.target.name.replace('-articleWords','');
        let tempLinkCheckoutList: Array<LinkCheckoutModel> = [...linkCheckoutList]
        let linkExists = tempLinkCheckoutList.find((item:LinkCheckoutModel) => item.id === productId);
        if(linkExists){
            tempLinkCheckoutList = tempLinkCheckoutList.map((item:LinkCheckoutModel)=>{
                if(item.id === linkExists?.id){
                    item.articleWords = event.target.value;
                }
                return item;
            })
        }else{
            tempLinkCheckoutList.push({
                ...item,
                articleWords:event.target.value
            })
        }

        saveLinkAdditionalInfo(tempLinkCheckoutList)
        setLinkCheckoutList(tempLinkCheckoutList);
    }

    return (
        <div className="mt-6 flex flex-col gap-6">
            <h3 className="text-2xl font-semibold text-[#1B1C19]">
                Cart Items ({linkCheckoutList.length})
            </h3>
            <div className="hidden border-b border-[#EBEBEB] pb-5 pl-3 sm:flex">
                <p className="w-1/2 text-base font-normal text-[#708191]">
                    Domain
                </p>
                <p className="w-1/3 text-base font-normal text-[#708191]">
                    Price
                </p>
            </div>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col gap-5"
            >
                {linkCheckoutList &&
                    linkCheckoutList.map((item: LinkCheckoutModel) => {
                        return (
                            <div key={item.id} className="flex flex-col">
                                <div
                                    className={
                                        'flex flex-col border border-[#EBEBEB] px-3 py-3 xs:flex-row '
                                    }
                                >
                                    <div className="order-2 flex w-full flex-col xs:order-1 sm:flex-row">
                                        <p className="w-full break-words text-center xs:text-start sm:w-1/2">
                                            {item.link}
                                        </p>
                                        <p className="w-full text-center xs:text-start sm:w-1/2 sm:pl-3">
                                            {item.price
                                                ? '€' + item.price.toFixed(2)
                                                : ''}
                                        </p>
                                    </div>
                                    <button
                                        className="order-1 mx-auto border-0 bg-transparent xs:order-2 xs:ml-auto"
                                        onClick={() => removeProduct(item.id)}
                                    >
                                        <img
                                            src={cross}
                                            alt="cross"
                                            className="h-6 w-6"
                                        />
                                    </button>
                                </div>
                                <div className="flex flex-col gap-6 rounded-bl-[20px] rounded-br-[20px] border border-t-0 border-[#EBEBEB] p-6">
                                    <div className="flex flex-col justify-between gap-3 sm:flex-row">
                                        <FormField
                                            label="Topic of the article"
                                            name={item.id + '-topic'}
                                            type="text"
                                            placeholder="Enter the Topic"
                                            register={register}
                                            required
                                            className="w-full sm:w-1/2"
                                            errors={errors}
                                        ></FormField>
                                        <FormField
                                            label="Anchor Tag"
                                            name={item.id + '-anchorTag'}
                                            type="text"
                                            placeholder="Enter the Anchor Tag"
                                            register={register}
                                            required
                                            className="w-full sm:w-1/2"
                                            errors={errors}
                                        ></FormField>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <p className="text-sm font-bold text-[#1B1C19]">
                                            Target placement{' '}
                                            <span
                                                className={
                                                    'text-sm font-bold text-[#FF7E66]'
                                                }
                                            >
                                                {' '}
                                                *
                                            </span>
                                        </p>
                                        <div className="flex gap-4">
                                            <label
                                                htmlFor="placement-any"
                                                className="flex items-center gap-2 text-sm font-normal text-[#1F1F1F]"
                                            >
                                                <input
                                                    type="radio"
                                                    value={'anywhere'}
                                                    id="placement-any"
                                                    {...register(
                                                        item.id + '-placement',
                                                        { required: true }
                                                    )}
                                                />
                                                Anywhere
                                            </label>
                                            <label
                                                htmlFor="placement-header"
                                                className="flex items-center gap-2 text-sm font-normal text-[#1F1F1F]"
                                            >
                                                <input
                                                    type="radio"
                                                    value={'header'}
                                                    id="placement-header"
                                                    {...register(
                                                        item.id + '-placement',
                                                        { required: true }
                                                    )}
                                                />
                                                Header
                                            </label>
                                            <label
                                                htmlFor="placement-body"
                                                className="flex items-center gap-2 text-sm font-normal text-[#1F1F1F]"
                                            >
                                                <input
                                                    type="radio"
                                                    value={'body'}
                                                    id="placement-body"
                                                    {...register(
                                                        item.id + '-placement',
                                                        { required: true }
                                                    )}
                                                />
                                                Body
                                            </label>
                                            <label
                                                htmlFor="placement-footer"
                                                className="flex items-center gap-2 text-sm font-normal text-[#1F1F1F]"
                                            >
                                                <input
                                                    type="radio"
                                                    value={'footer'}
                                                    id="placement-footer"
                                                    {...register(
                                                        item.id + '-placement',
                                                        { required: true }
                                                    )}
                                                />
                                                Footer
                                            </label>
                                        </div>
                                    </div>

                                    <div className="flex flex-col justify-between gap-3 sm:flex-row">
                                        <FormField
                                            label="URL Source"
                                            name={item.id + '-urlSource'}
                                            type="text"
                                            placeholder="Enter the URL Source"
                                            register={register}
                                            required
                                            className="w-full sm:w-1/2"
                                            errors={errors}
                                        ></FormField>
                                        <SelectField className='w-full sm:w-1/2' 
                                            title="Amount of words Article needs " 
                                            note="(Every 100 words over 500 costs €15)"
                                            register={register}
                                            errors={errors} 
                                            onValueChangeHandler={(event)=>articleWordsOnchangeHandler(event,item)}
                                            items={articleWords} 
                                            name={item.id + '-articleWords'}  />
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <p className="text-sm font-bold text-[#1B1C19]">
                                            Trust links{' '}
                                            <span
                                                className={
                                                    'text-sm font-bold text-[#FF7E66]'
                                                }
                                            >
                                                {' '}
                                                *
                                            </span>
                                        </p>
                                        <div className="flex gap-4">
                                            <label
                                                htmlFor="trust-link"
                                                className="flex items-center gap-2 text-sm font-normal text-[#1F1F1F]"
                                            >
                                                <input
                                                    type="radio"
                                                    value={'true'}
                                                    id="trust-link"
                                                    {...register(
                                                        item.id + '-trustLink',
                                                        { required: true }
                                                    )}
                                                />
                                                Yes
                                            </label>
                                            <label
                                                htmlFor="trust-link-no"
                                                className="flex items-center gap-2 text-sm font-normal text-[#1F1F1F]"
                                            >
                                                <input
                                                    type="radio"
                                                    value={'false'}
                                                    id="trust-link-no"
                                                    {...register(
                                                        item.id + '-trustLink',
                                                        { required: true }
                                                    )}
                                                />
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <FormField
                                            label="Notes"
                                            name={item.id + '-notes'}
                                            type="text"
                                            placeholder="Optional notes"
                                            register={register}
                                            className="w-full"
                                        ></FormField>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                <div className="mt-auto flex flex-col items-center justify-between gap-5 pt-5 xs:flex-row">
                    {activeStep === 1 && (
                        <button
                            className="mx-auto text-base font-semibold text-[#06D7A0] hover:text-[#06d79fb4] xs:ml-0 xs:mr-auto"
                            onClick={() => navigate('/marketplace')}
                        >
                            Continue shopping
                        </button>
                    )}
                    {activeStep !== steps.length && (
                        <PrimaryButton
                            className="mx-auto xs:ml-auto xs:mr-0"
                            label="Next"
                            type="submit"
                        />
                    )}
                </div>
            </form>
        </div>
    );
};

export default LinkAdditionalInfoStep;
