import { useEffect, useState } from 'react';
import Header from 'src/components/layout/Header';
import { client } from 'src/utils/client';
import { GET_DASHBOARD } from 'src/const/apiUrls';
import { Dashboard } from './Home.interface';
import DashboardWidgets from './components/DashboardWidgets';
import MarketplaceTable from './components/tables/MarketplaceTable';
import OrdersTable from './components/tables/OrdersTable';
import RecommendationTable from './components/tables/RecommendationTable';
import FocusDomainInfo from './components/FocusDomainInfo';
import CompetitionTable from './components/tables/CompetitionTable';
import { toast } from 'react-toastify';

const Home = () => {
    const [dashboard, setDashboard] = useState<Dashboard>();

    const fetchData = async () => {
        try {
            let resp = await client.get(GET_DASHBOARD);
            if (resp && resp.data) {
                let data: Dashboard = resp.data;
                setDashboard(data);
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong!');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="flex flex-col gap-8">
            <Header title="Dashboard" />
            <div className="flex w-full flex-col gap-5">
                <DashboardWidgets dashboard={dashboard?.dashboardData} />
                <FocusDomainInfo
                    reloadHomePageData={fetchData}
                    domainInfo={
                        dashboard && dashboard?.focusDomainInfo ? dashboard?.focusDomainInfo.domainInfo : null
                    }
                />
                <div className="flex flex-col gap-5 xl:flex-row">
                    <MarketplaceTable className="w-full xl:w-1/2" />
                    <RecommendationTable
                        links={dashboard ? dashboard.recommendedLinks : []}
                        className="w-full xl:w-1/2"
                    />
                </div>
                <div className="flex flex-col gap-5 xl:flex-row">
                    <OrdersTable className="w-full xl:w-1/2" />
                    <CompetitionTable
                        domains={
                            dashboard && dashboard.focusDomainInfo
                                ? dashboard.focusDomainInfo.relatedDomains
                                : []
                        }
                        className="w-full xl:w-1/2"
                    />
                </div>
            </div>
        </div>
    );
};

export default Home;
