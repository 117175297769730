import completed from 'src/assets/icons/completed.svg';
import earnings from 'src/assets/icons/earnings.svg';
import inProgress from 'src/assets/icons/inProgress.svg';
import spent from 'src/assets/icons/spent.svg';
import total from 'src/assets/icons/total.svg';
import { DashboardData } from '../Home.interface';
import Widget from './Widget';

type Props = {
    dashboard: DashboardData | undefined;
};

const DashboardWidgets = ({ dashboard }: Props) => {
    return (
        <div className="flex w-full flex-wrap">
            <div className="mx-auto w-full p-1 xs:w-3/5 sm:mx-0 sm:w-1/2 md:w-1/3 lg:w-1/5">
                <Widget
                    image={earnings}
                    label="Spent last month"
                    value={
                        dashboard && dashboard.totalSpentLastMonth
                            ? '€' + dashboard.totalSpentLastMonth
                            : '€0'
                    }
                    valueColor="text-[#06D7A0]"
                />
            </div>
            <div className="mx-auto w-full p-1 xs:w-3/5 sm:mx-0 sm:w-1/2 md:w-1/3 lg:w-1/5">
                <Widget
                    image={spent}
                    label="Spent this month"
                    value={
                        dashboard && dashboard.totalSpentThisMonth
                            ? '€' + dashboard.totalSpentThisMonth
                            : '€0'
                    }
                    valueColor="text-[#06D7A0]"
                />
            </div>
            <div className="mx-auto w-full p-1 xs:w-3/5 sm:mx-0 sm:w-1/2 md:w-1/3 lg:w-1/5">
                <Widget
                    image={inProgress}
                    label="Orders in progress"
                    value={
                        dashboard && dashboard.totalOrdersInProgress
                            ? dashboard.totalOrdersInProgress
                            : '0'
                    }
                    valueColor="text-[#FFB44E]"
                />
            </div>
            <div className="mx-auto w-full p-1 xs:w-3/5 sm:mx-0 sm:w-1/2 md:w-1/3 lg:w-1/5">
                <Widget
                    image={completed}
                    label="Orders completed"
                    value={
                        dashboard && dashboard.totalOrdersCompleted
                            ? dashboard.totalOrdersCompleted
                            : '0'
                    }
                    valueColor="text-[#06D7A0]"
                />
            </div>
            <div className="mx-auto w-full p-1 xs:w-3/5 sm:mx-0 sm:w-1/2 md:w-1/3 lg:w-1/5">
                <Widget
                    image={total}
                    label="Total orders"
                    value={
                        dashboard && dashboard.totalOrders
                            ? dashboard.totalOrders
                            : '0'
                    }
                    valueColor="text-[#06D7A0]"
                />
            </div>
        </div>
    );
};

export default DashboardWidgets;
