import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import Header from "src/components/layout/Header"
import TableHeader from "src/components/table/TableHeader"
import { GET_PURCHASED_LINKS } from "src/const/apiUrls"
import { client } from "src/utils/client"
import { PurchasedLink } from "./PurchasedLinks.interface"
import { CircularProgress, LinearProgress } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { columns } from "./TableColumns"

type Props = {}

const PurchasedLinks = (props: Props) => {

  const [links, setLinks] = useState<Array<PurchasedLink>>([]);
  const [filteredLinks, setFilteredLinks] = useState<Array<PurchasedLink>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
          let resp = await client.get(GET_PURCHASED_LINKS);
          if (resp && resp.data && resp.status === 200) {
            setLinks(resp.data)
            setFilteredLinks(resp.data)
            setLoading(false)
          }
      } catch (error) {
          console.log(error);
          setLoading(false)
          toast.error('Something went wrong!');
      }
    };
    fetchData();
  }, [])

  return (
    <div className="flex flex-col gap-8">
      <Header title="Purchased links" />
      <div>
        <TableHeader
            showSearch={false}
            totalRows={links.length}
            searchTotalRows={filteredLinks.length}
        ></TableHeader>
        <div className="d-flex w-full items-center justify-center">
            {loading && (
                <CircularProgress
                    style={{
                        position: 'relative',
                        top: '30vh',
                        left: '50%',
                        color: '#06D7A0',
                    }}
                />
            )}
            {!loading && filteredLinks && filteredLinks.length > 0 && (
                <DataGrid
                    sx={{
                        background: '#FFF',
                        borderBottomRightRadius: '20px',
                        borderBottomLeftRadius: '20px',
                        borderTopLeftRadius: '0px',
                        borderTopRightRadius: '0px',
                        border: 'none',
                    }}
                    slots={{
                        loadingOverlay: LinearProgress,
                    }}
                    getRowId={(row: any) => row.link}
                    rows={filteredLinks}
                    columns={columns}
                    disableRowSelectionOnClick
                    disableColumnMenu
                    loading={loading}
                    getRowClassName={(params: any) => {
                        let textClass =
                            'text-sm text-[#1B1C19] border-0 ';
                        let backPrimaryClass = 'bg-[#FFFFFF] ';
                        let backSecondaryClass = 'bg-[#FAFAFA] ';
                        return params.indexRelativeToCurrentPage % 2 ===
                            0
                            ? textClass + backPrimaryClass
                            : textClass + backSecondaryClass;
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 25 },
                        },
                    }}
                    pageSizeOptions={[25, 50, 100]}
                />
            )}
            {filteredLinks &&
                filteredLinks.length === 0 &&
                !loading && (
                    <div className="flex w-full justify-center bg-[#fff] p-5 font-bold">
                        No data found
                    </div>
                )}
        </div>
      </div>
    </div>
  )
}

export default PurchasedLinks