import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import SecondaryButton from 'src/components/buttons/SecondaryButton';
import FormField from 'src/components/formField/FormField';
import { UPDATE_USER_PASSWORD } from 'src/const/apiUrls';
import { client } from 'src/utils/client';
import { ChangePasswordDTO } from '../Profile.interface';
import { logOut } from 'src/utils/auth';
import { useNavigate } from 'react-router-dom';

type Props = {};

const ChangePassword = (props: Props) => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<ChangePasswordDTO>();

    const onSubmit = async (data: ChangePasswordDTO) => {
        if (data.password === data.old_password) {
            toast.error('Password is already in use.');
            return;
        }

        if (data.password !== data.password_repeat) {
            toast.error("New password and repeat password doesn't match.");
            return;
        }
        try {
            let resp = await client.post(UPDATE_USER_PASSWORD, data);
            if (resp.data && resp.data.success) {
                reset();
                logOut();
                navigate('/sign-in');
                toast.success(
                    'You have successfully changed password.Please sign in with new password.'
                );
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong!');
        }
    };

    return (
        <div className="flex flex-col">
            <h3 className="pb-6 pt-4 text-xl font-semibold text-[#1B1C19]">
                Change password
            </h3>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex w-full flex-col gap-6"
            >
                <FormField
                    name="old_password"
                    register={register}
                    type="password"
                    label="Current password"
                    placeholder="Enter your current password"
                    className="w-1/2"
                    required
                    errors={errors}
                />
                <FormField
                    className="w-1/2"
                    name="password"
                    register={register}
                    type="changePassword"
                    label="New password"
                    placeholder="Enter your new password"
                    required
                    errors={errors}
                />
                <FormField
                    name="password_repeat"
                    className="w-1/2"
                    register={register}
                    type="changePassword"
                    label="Repeat password"
                    placeholder="Enter your repeat password"
                    required
                    errors={errors}
                />
                <p className="text-sm text-[#708191] ">
                    Password should be min of{' '}
                    <span className="font-semibold">10 characters *</span> (and
                    up to 100 characters),
                    <span className="font-semibold">
                        One Upper Case Character*
                    </span>{' '}
                    and{' '}
                    <span className="font-semibold">
                        One Special Character*
                    </span>{' '}
                    e.g., ! @ # ? included.
                </p>
                <div className="flex justify-end gap-8">
                    <SecondaryButton
                        label="Cancel"
                        type="button"
                        onClick={() => {}}
                    />

                    <PrimaryButton
                        type={'submit'}
                        label="Submit"
                        className="w-fit"
                    />
                </div>
            </form>
        </div>
    );
};

export default ChangePassword;
