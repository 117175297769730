import { GridColDef } from '@mui/x-data-grid';

export const columns: GridColDef[] = [
    {
        field: 'Domain',
        headerName: 'Domain',
        headerClassName: 'text-xs text-[#708191] ',
        flex: 1,
        minWidth: 120,
    },

    {
        field: 'Instances',
        headerName: 'Instances',
        headerClassName: 'text-xs text-[#708191]',
        flex: 1,
        minWidth: 110,
    },
    {
        field: 'CitationFlow',
        headerName: 'Cf',
        headerClassName: 'text-xs text-[#708191]',
        flex: 1,
        minWidth: 100,
    },
    {
        field: 'TrustFlow',
        headerName: 'Tf',
        headerClassName: 'text-xs text-[#708191]',
        flex: 1,
        minWidth: 100,
    },
    {
        field: 'UniqueContexts',
        headerName: 'Unique contexts',
        headerClassName: 'text-xs text-[#708191]',
        flex: 1,
        minWidth: 140,
    },
    {
        field: 'PrimaryTopicName',
        headerName: 'Topic',
        headerClassName: 'text-xs text-[#708191]',
        flex: 1,
        minWidth: 150,
    },
    {
        field: 'PrimaryTopicValue',
        headerName: 'Topic value',
        headerClassName: 'text-xs text-[#708191]',
        flex: 1,
        minWidth: 110,
    },
    {
        field: 'Title',
        headerName: 'Title',
        headerClassName: 'text-xs text-[#708191]',
        flex: 1,
        minWidth: 180,
    },
];
