import {
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
} from '@mui/x-data-grid';
import { Link } from './Marketplace.interface';
import TableBuyButton from '../checkout/TableBuyButton';
import star from 'src/assets/icons/MaterialSymbolsStar.svg';
import starOutline from 'src/assets/icons/MaterialSymbolsStarOutline.svg';
import newIcon from 'src/assets/icons/MaterialSymbolsFiberNew.svg';
import { UPDATE_FAVORITE_LINK } from 'src/const/apiUrls';
import { client } from 'src/utils/client';
import { toast } from 'react-toastify';

const onIconClick = async (e: any, sku: string) => {
    let dataIsFavorite = e.target.getAttribute('data-isfavorite');
    if (dataIsFavorite === 'favoriteIcon') {
        e.target.setAttribute('data-isfavorite', 'unfavoriteIcon');
        e.target.setAttribute('src', starOutline);
    }
    if (dataIsFavorite === 'unfavoriteIcon') {
        e.target.setAttribute('data-isfavorite', 'favoriteIcon');
        e.target.setAttribute('src', star);
    }
    try {
        await client.post(UPDATE_FAVORITE_LINK, {
            sku_id: sku,
        });
    } catch (error) {
        console.log(error);
        toast.error('Something went wrong!');
    }
};

export const columns: GridColDef[] = [
    {
        field: 'isFavorite',
        headerName: '',
        headerClassName: 'text-xs text-[#708191]',
        flex: 0.1,
        sortable: false,
        minWidth: 60,
        renderCell: (params) => {
            let newComponent = params.row.is_new ? (
                <img src={newIcon} alt="New" className="menu-icon h-6 w-6" />
            ) : (
                ''
            );

            let component = params.row.is_favorite ? (
                <div className="flex flex-col">
                    <img
                        onClick={(e: any) => onIconClick(e, params.row.sku)}
                        src={star}
                        alt="Menu"
                        data-isfavorite="favoriteIcon"
                        id="favoriteIcon"
                        className="menu-icon h-6 w-6 hover:cursor-pointer"
                    />
                    {newComponent}
                </div>
            ) : (
                <div className="flex flex-col">
                    <img
                        onClick={(e: any) => onIconClick(e, params.row.sku)}
                        src={starOutline}
                        alt="Menu"
                        data-isfavorite="unfavoriteIcon"
                        id="unfavoriteIcon"
                        className="menu-icon h-6 w-6 hover:cursor-pointer"
                    />
                    {newComponent}
                </div>
            );

            return component;
        },
    },
    {
        field: 'action',
        headerName: '',
        sortable: false,
        flex: 0.5,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<Link>) => {
            return <TableBuyButton params={params} />;
        },
    },
    {
        field: 'price',
        type: 'number',
        align: 'center',
        headerAlign: 'center',
        headerName: 'Price',
        headerClassName: 'text-xs text-[#708191] ',
        flex: 0.5,
        sortable: true,
        minWidth: 100,
        valueFormatter: (params: GridValueFormatterParams<Link>) => {
            if (params.value == null) {
                return '';
            }
            return `€${params.value}`;
        },
    },
    {
        field: 'title',
        headerName: 'Domain',
        headerClassName: 'text-xs text-[#708191]',
        flex: 1,
        minWidth: 200,
        renderCell: (params) => (
            <a
                className="underline"
                href={'https://' + params.value}
                target="_blank"
                rel="noopener noreferrer"
            >
                {params.value}
            </a>
        ),
    },
    {
        field: 'language',
        headerName: 'Language',
        headerClassName: 'text-xs text-[#708191]',
        flex: 0.7,
        minWidth: 110,
    },
    {
        field: 'country',
        headerName: 'Country',
        headerClassName: 'text-xs text-[#708191]',
        flex: 0.7,
        minWidth: 100,
    },
    {
        field: 'category',
        headerName: 'Category',
        headerClassName: 'text-xs text-[#708191]',
        flex: 1,
        minWidth: 140,
    },
    {
        field: 'link_type',
        headerName: 'Type',
        headerClassName: 'text-xs text-[#708191]',
        flex: 1,
        minWidth: 80,
        valueFormatter: (params: GridValueFormatterParams<Link>) => {
            if (params.value == null) {
                return '';
            }
            let type: string = params.value + '';
            return type[0] ? type[0].toUpperCase() + type.slice(1) : '';
        },
    },
    {
        field: 'backlinks',
        headerName: 'Backlinks',
        type: 'number',
        headerClassName: 'text-xs text-[#708191]',
        flex: 0.7,
        minWidth: 120,
    },
    {
        field: 'organic_traffic',
        type: 'number',
        headerName: 'Organic traffic',
        headerClassName: 'text-xs text-[#708191]',
        flex: 0.5,
        minWidth: 120,
    },
    {
        field: 'domain_trust',
        type: 'number',
        headerName: 'Domain trust',
        headerClassName: 'text-xs text-[#708191]',
        flex: 0.5,
        minWidth: 100,
    },
    {
        field: 'page_trust',
        type: 'number',
        headerName: 'Page trust',
        headerClassName: 'text-xs text-[#708191]',
        flex: 0.5,
        minWidth: 100,
    },
    {
        field: 'keywords_count',
        type: 'number',
        headerName: 'Keywords',
        headerClassName: 'text-xs text-[#708191]',
        flex: 0.5,
        minWidth: 100,
    },
];
