import PrimaryButton from 'src/components/buttons/PrimaryButton';
import domainInFocus from 'src/assets/icons/domain-in-focus.svg';
import domainRating from 'src/assets/icons/domain-rating.svg';
import organicTraffic from 'src/assets/icons/organic-traffic.svg';
import { DomainInfo } from '../Home.interface';
import { useState } from 'react';
import FocusDomainDialog from './FocusDomainDialog';

type Props = {
    domainInfo: DomainInfo | null;
    reloadHomePageData: () => void;
};

const FocusDomainInfo = ({ domainInfo, reloadHomePageData }: Props) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    return (
        <div className="flex w-full flex-col gap-5 rounded-2xl bg-[#fff] p-5">
            <div className=" flex  w-full justify-end">
                <PrimaryButton
                    className="ml-auto p-2 text-xs"
                    label="Change domain"
                    type="button"
                    onClick={() => setIsDialogOpen(true)}
                />
            </div>
            <div className="flex flex-col gap-5 lg:flex-row lg:gap-0">
                <div className="flex w-full flex-col gap-5 px-3  lg:w-1/2 lg:border-r">
                    <div className="flex flex-col items-center xl:flex-row">
                        <div className="order-2 flex gap-4 self-start xl:order-1">
                            <img
                                className="h-12 w-12"
                                src={domainInFocus}
                                alt="domainInFocus"
                            />
                            <div className="flex flex-col">
                                <p className="text-sm">Domain in focus</p>
                                <p className="text-xs font-bold xs:text-sm sm:text-base">
                                    {domainInfo ? domainInfo.title : ''}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="flex items-center gap-4">
                            <img
                                className="h-12 w-12"
                                src={organicTraffic}
                                alt="organicTraffic"
                            />
                            <div className="flex flex-col">
                                <p className="text-sm">Ref domains</p>
                                <p className="text-xs font-bold text-[#06D7A0] xs:text-sm sm:text-base">
                                    {domainInfo ? domainInfo.refDomains : ''}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="flex items-center gap-4">
                            <img
                                className="h-12 w-12"
                                src={domainRating}
                                alt="organicTraffic"
                            />
                            <div className="flex flex-col">
                                <p className="text-sm">Language</p>
                                <p className="text-xs font-bold text-[#06D7A0] xs:text-sm sm:text-base">
                                    {domainInfo ? domainInfo.language : ''}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" flex w-full flex-col gap-5 px-4 lg:w-1/2">
                    <div className="flex h-12 flex-col pl-[60px]">
                        <p className="text-sm">Category</p>
                        <p className="text-xs font-bold xs:text-sm sm:text-base">
                            {domainInfo ? domainInfo.category : ''}
                        </p>
                    </div>
                    <div className="flex h-12 flex-col pl-[60px]">
                        <p className="text-sm">Citation flow</p>
                        <p className="text-xs font-bold text-[#06D7A0] xs:text-sm sm:text-base">
                            {domainInfo ? domainInfo.cf : ''}
                        </p>
                    </div>
                    <div className="flex h-12 flex-col pl-[60px]">
                        <p className="text-sm">Trust flow</p>
                        <p className="text-xs font-bold text-[#06D7A0] xs:text-sm sm:text-base">
                            {domainInfo ? domainInfo.tf : ''}
                        </p>
                    </div>
                </div>
            </div>
            <FocusDomainDialog
                reloadHomePageData={reloadHomePageData}
                focusedDomain={domainInfo ? domainInfo.title : ''}
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
            ></FocusDomainDialog>
        </div>
    );
};

export default FocusDomainInfo;
