import { BillingInformationModel } from "src/pages/checkout/Checkout.interface";

let key = 'billingInfo'

export const saveBillingInfo = (billingInfo:BillingInformationModel) =>{
    if (typeof window !== 'undefined' && billingInfo) {
        window.localStorage.setItem(key, JSON.stringify(billingInfo));
    }
}

export const getBillingInfo = () =>{
    if (typeof window !== 'undefined' ) {
        let localStorageString = window.localStorage.getItem(key);
        if (localStorageString) {
            let billingInfo: BillingInformationModel =
                JSON.parse(localStorageString);
            return billingInfo;
        }
    }
}
export const removeBillingInfo = () =>{
    if (typeof window !== 'undefined' ) {
        window.localStorage.removeItem(key);
    }
}