import PrimaryButton from 'src/components/buttons/PrimaryButton';
import logo from 'src/assets/images/black-logo.svg';
import backImg from 'src/assets/images/confirm-email.svg';
import { useNavigate } from 'react-router';

const ConfirmEmail = () => {
    const navigate = useNavigate();
    return (
        <div className="flex h-screen w-screen">
            <div className="flex w-1/2 flex-col items-center justify-center bg-[#DEFD71]">
                <img
                    className="w-full p-8 sm:p-12 md:w-1/2 md:p-0 lg:w-1/2 xl:w-1/3"
                    src={logo}
                    alt="d_tre"
                />
                <img
                    className="w-full md:w-3/4 lg:w-2/3 xl:w-1/2"
                    src={backImg}
                    alt="login"
                />
            </div>
            <div className="flex w-1/2 flex-col items-center justify-center gap-7">
                <h1 className="w-full text-center text-3xl lg:w-3/4 lg:text-3xl">
                    Success !
                </h1>
                <p className="w-full px-2 text-center text-sm lg:w-1/2 lg:px-0">
                    An email has been send to youremail@domain.com. Please check
                    for an email from company and click on the included link to
                    reset your password.
                </p>
                <form className="flex w-full flex-col gap-5 px-3 md:w-4/5 md:px-0 lg:w-2/3 xl:w-1/2">
                    <PrimaryButton
                        onClick={() => navigate('/')}
                        label="Back to home"
                    />
                </form>
            </div>
        </div>
    );
};

export default ConfirmEmail;
