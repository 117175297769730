import { Link } from 'react-router-dom';
import { UseFormRegister } from 'react-hook-form';

type Props = {
    label: string;
    name: string;
    type: string;
    register: UseFormRegister<any>;
    placeholder?: string;
    showForgotPassword?: boolean;
    required?: boolean;
    disabled?: boolean | undefined;
    errors?: any;
    className?: string;
};

const FormField = ({
    name,
    label,
    type,
    register,
    placeholder,
    showForgotPassword,
    required,
    errors,
    className,
    disabled,
}: Props) => {
    let inputField;

    switch (type) {
        case 'email':
            inputField = (
                <input
                    className="focus:border-1 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191] focus:border-[#06D7A0]"
                    {...register(name, {
                        required: {
                            value: required ? required : false,
                            message: 'Required Field',
                        },
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'Invalid email address',
                        },
                    })}
                    type={type}
                    placeholder={placeholder}
                    disabled={disabled}
                ></input>
            );
            break;
        case 'password':
            inputField = (
                <input
                    className="focus:border-1 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191] focus:border-[#06D7A0]"
                    {...register(name, {
                        required: {
                            value: required ? required : false,
                            message: 'Required Field',
                        },
                    })}
                    type={type}
                    disabled={disabled}
                    placeholder={placeholder}
                ></input>
            );
            break;
        case 'changePassword':
            inputField = (
                <input
                    className="focus:border-1 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191] focus:border-[#06D7A0]"
                    {...register(name, {
                        required: {
                            value: required ? required : false,
                            message: 'Required Field',
                        },
                        minLength: {
                            value: 10,
                            message:
                                'Password should be minimum of 10 characters',
                        },
                        maxLength: {
                            value: 100,
                            message:
                                'Password should be maximum of 100 characters',
                        },
                        pattern: {
                            value: /^(?=.*[A-Z])(?=.*[!@#?]).+$/,
                            message:
                                'Password should contain at least One Upper Case Character* and One Special Character*',
                        },
                    })}
                    type={'password'}
                    disabled={disabled}
                    placeholder={placeholder}
                ></input>
            );
            break;
        case 'text':
            inputField = (
                <input
                    className="focus:border-1 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191] focus:border-[#06D7A0]"
                    {...register(name, {
                        required: {
                            value: required ? required : false,
                            message: 'Required Field',
                        },
                    })}
                    type={type}
                    disabled={disabled}
                    placeholder={placeholder}
                ></input>
            );
            break;
        case 'textArea':
            inputField = (
                <textarea
                    className="focus:border-1 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191] focus:border-[#06D7A0]"
                    {...register(name, {
                        required: {
                            value: required ? required : false,
                            message: 'Required Field',
                        },
                    })}
                    rows={4}
                    disabled={disabled}
                    placeholder={placeholder}
                ></textarea>
            );
            break;
        default:
            inputField = <div></div>;
    }

    return (
        <div className={'flex flex-col gap-2 ' + className}>
            <div className="flex items-center justify-between">
                <label className="text-sm font-bold text-[#1B1C19]">
                    {label}
                    <span
                        className={
                            required
                                ? 'text-sm font-bold text-[#FF7E66]'
                                : 'hidden'
                        }
                    >
                        {' '}
                        *
                    </span>
                </label>
                {showForgotPassword && (
                    <Link
                        className="text-bold text-right text-sm font-bold text-[#06D7A0] hover:text-[#06d79f8e]"
                        to={'/forgot-password'}
                    >
                        Forgot your password?
                    </Link>
                )}
            </div>
            {inputField}
            {errors && (
                <span className="text-red-700">
                    {errors && errors[`${name}`] && errors[`${name}`].message}
                </span>
            )}
        </div>
    );
};

export default FormField;
