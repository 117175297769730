export const LOGIN = 'jwt-auth/v1/token';
export const GET_ALL_LINKS = 'linkbuilding-tool/v1/get-links';
export const CREATE_ORDER = 'linkbuilding-tool/v1/create-order';
export const GET_ALL_ORDERS = 'linkbuilding-tool/v1/get-orders';
export const GET_SINGLE_ORDER = 'linkbuilding-tool/v1/get-single-order';
export const GET_DASHBOARD = 'linkbuilding-tool/v1/get-dashboard';
export const UPDATE_USER = 'linkbuilding-tool/v1/update-user';
export const UPDATE_USER_PASSWORD = 'linkbuilding-tool/v1/update-user-password';
export const GET_USER = 'linkbuilding-tool/v1/get-user';
export const GET_ALL_CATEGORIES = 'linkbuilding-tool/v1/get-all-categories';
export const GET_ALL_COUNTRIES = 'linkbuilding-tool/v1/get-all-countries';
export const GET_LINK_COUNTRIES = 'linkbuilding-tool/v1/get-link-countries';
export const GET_NOTIFICATIONS = 'linkbuilding-tool/v1/get-notifications';
export const MARK_AS_READ_NOTIFICATION = 'linkbuilding-tool/v1/mark-notification-as-read';
export const UPDATE_ORDER_STATUS = 'linkbuilding-tool/v1/update-order-status/';
export const UPDATE_FAVORITE_LINK = 'linkbuilding-tool/v1/update-favorite-link/';
export const GET_FAVORITE_LINKS = 'linkbuilding-tool/v1/get-favorite-links/';
export const GET_PURCHASED_LINKS = 'linkbuilding-tool/v1/get-ordered-live-links/';