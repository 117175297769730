type Props = {
    label: string;
    value: string | number;
    image: any;
    valueColor: string;
};

const Widget = ({ label, value, image, valueColor }: Props) => {
    return (
        <div className="flex h-full w-full gap-4 rounded-2xl bg-[#fff] px-3 py-4 ">
            <img className="h-12 w-12" src={image} alt="Spent last month" />
            <div className="flex flex-col">
                <p className="text-xs text-[#1B1C19]">{label}</p>
                <p className={'text-lg font-bold ' + valueColor}>{value}</p>
            </div>
        </div>
    );
};

export default Widget;
