import { Navigate, Outlet } from 'react-router-dom';
import Layout from '../layout/Layout';
import { getSession } from 'src/utils/auth';

const ProtectedRoutes = () => {
    const session = getSession();

    if (session.accessToken && session.validData) {
        return (
            <Layout>
                <Outlet />
            </Layout>
        );
    } else if (session.accessToken && !session.validData) {
        return <Navigate to="/create-profile" />;
    } else {
        return <Navigate to="/sign-in" />;
    }
};

export default ProtectedRoutes;
