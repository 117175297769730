import { Cart } from 'src/utils/cart/Cart.interface';
import { User } from '../profile/Profile.interface';
import { LinkCheckoutModel } from './Checkout.interface';
import { useEffect } from 'react';

type Props = {
    linkCheckoutList: Array<LinkCheckoutModel>;
    productList: Array<Cart>;
    totalPrice: number;
    subTotalPrice: number;
    setSubTotalPrice: any;
    tax: number;
    totalDiscount: number;
    className?: string;
    user?: User;
};

const CheckoutSummary = ({
    productList,
    className,
    tax,
    subTotalPrice,
    totalPrice,
    user,
    totalDiscount,
    linkCheckoutList,
    setSubTotalPrice
}: Props) => {

    useEffect(() => {
        
    }, [linkCheckoutList])
    
    return (
        <div className={className + ' flex flex-col'}>
            <h2 className="pb-5 text-xl font-bold text-[#1B1C19]">
                Order summary
            </h2>
            <div className="flex flex-col gap-3 py-6">
                {productList &&
                    productList.map((item: Cart, index: number) => {
                        let linkdAdditionalData = linkCheckoutList.find((linkData:LinkCheckoutModel)=>item.id === linkData.id);
                        let additionalCost = 0;
                        if(linkdAdditionalData){
                            let numberOfWords = linkdAdditionalData.articleWords ? Number(linkdAdditionalData.articleWords):500;
                            let defaultNumberOfWords = 500;
                            additionalCost = ((numberOfWords / 100) - (defaultNumberOfWords / 100)) * 15;
                        }
                        return (
                            <div
                                key={item.id + '-' + index}
                                className="flex flex-col items-center justify-between gap-3"
                            >
                                <div className="flex flex-1 items-center justify-between w-full">
                                    <p className="break-all pr-6 text-xs text-[#1B1C19] xs:text-base">
                                        {item.name}
                                    </p>
                                    <p className="text-base font-semibold text-[#1B1C19] xs:text-xl">
                                        {'€' + item.price.toFixed(2)}
                                    </p>
                                </div>
                                {linkdAdditionalData && linkdAdditionalData.articleWords && Number(linkdAdditionalData.articleWords) > 500 &&
                                    <div className="flex items-center justify-between w-full">
                                        <p className="break-all text-[10px] text-[#1B1C19] xs:text-xs">
                                            Additional content
                                        </p>
                                        <p className="text-xs font-semibold text-[#1B1C19] xs:text-base">
                                            {'€' + additionalCost.toFixed(2)}
                                        </p>
                                    </div>
                                }
                            </div>
                        );
                    })}
            </div>
            <div className="flex flex-col gap-4 border-y border-[#EBEBEB] py-6">
                <div className="flex justify-between">
                    <p className="text-xs font-bold text-[#1B1C19] xs:text-base">
                        Sub Total
                    </p>
                    <p className="text-base font-semibold text-[#1B1C19] xs:text-xl">
                        {'€' + subTotalPrice.toFixed(2)}
                    </p>
                </div>
                {user && user.discountAmount !== 0 && (
                    <div className="flex justify-between">
                        <p className="text-xs font-bold text-[#1B1C19] xs:text-base">
                            Discount ({user ? user.discountAmount : ''}%)
                        </p>
                        <p className="text-base font-semibold text-[#1B1C19] xs:text-xl">
                            {totalDiscount
                                ? '€' + totalDiscount.toFixed(2)
                                : ''}
                        </p>
                    </div>
                )}
                {user && !user.taxFree && (
                    <div className="flex justify-between">
                        <p className="text-xs font-bold text-[#1B1C19] xs:text-base">
                            excl. {user && user.vatRate ? user.vatRate : '0'}%
                            Tax
                        </p>
                        <p className="text-base font-semibold text-[#1B1C19] xs:text-xl">
                            {'€' + tax.toFixed(2)}
                        </p>
                    </div>
                )}
            </div>
            <div className="mt-6 flex flex-col">
                <div className="flex justify-between">
                    <p className="text-xs font-semibold text-[#1B1C19] xs:text-base">
                        Total Amount Price:
                    </p>
                    <p className="text-base font-bold text-[#06D7A0] xs:text-2xl">
                        {'€' + totalPrice.toFixed(2)}
                    </p>
                </div>
                {user && !user.taxFree && (
                    <p className="text-right text-xs font-normal text-[#1B1C19] xs:text-sm">
                        VAT included in totals
                    </p>
                )}
            </div>
        </div>
    );
};

export default CheckoutSummary;
