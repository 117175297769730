import TableHeader from 'src/components/table/TableHeader';
import { useEffect, useRef, useState } from 'react';
import { client } from 'src/utils/client';
import {
    GET_ALL_CATEGORIES,
    GET_FAVORITE_LINKS,
    GET_LINK_COUNTRIES,
} from 'src/const/apiUrls';
import { columns } from './TableColumns';
import { CircularProgress, LinearProgress } from '@mui/material';
import SelectField from 'src/components/formField/SelectField';
import { Category } from 'src/interfaces/Category.interface';
import { SelectFieldData } from 'src/components/formField/SelectField.interface';
import SecondaryButton from 'src/components/buttons/SecondaryButton';
import { toast } from 'react-toastify';
import Multiselect from 'multiselect-react-dropdown';
import { Filter, Link } from '../marketplace/Marketplace.interface';
import Header from 'src/components/layout/Header';
import { DataGrid } from '@mui/x-data-grid';


const FavoriteLinks = () => {
    const [links, setLinks] = useState<Array<Link>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [filteredLinks, setFilteredLinks] = useState<Array<Link>>([]);
    const [categories, setCategories] = useState<Array<Category>>([]);
    const [selectedCategories, setSelectedCategories] = useState<Array<Category>>([]);
    const [countries, setCountries] = useState<Array<SelectFieldData>>([]);
    const [filters, setFilters] = useState<Filter>({
        country: '',
        category: [],
        minPrice: undefined,
        maxPrice: undefined,
        minCf: undefined,
        maxCf: undefined,
    });
    const [search, setSearch] = useState<string>('');
    const formRef = useRef<HTMLFormElement>(null);
    const minPriceRef = useRef<HTMLInputElement>(null);
    const maxPriceRef = useRef<HTMLInputElement>(null);
    const minCf = useRef<HTMLInputElement>(null);
    const maxCf = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let resp = await client.get(GET_FAVORITE_LINKS);
                if (resp && resp.data && resp.status === 200) {
                    let tempLinks: Array<Link> = resp.data;
                    setLinks(tempLinks);
                    setFilteredLinks(tempLinks);
                    setLoading(false);
                }
            } catch (error) {
                console.log(error);
                toast.error('Something went wrong!');
            }
        };
        const fetchCategories = async () => {
            try {
                let resp = await client.get(GET_ALL_CATEGORIES);
                if (resp && resp.data && resp.status === 200) {
                    let tempCategories: Array<Category> = resp.data;
                    setCategories(tempCategories);
                }
            } catch (error) {
                console.log(error);
                toast.error('Something went wrong!');
            }
        };
        const fetchCountries = async () => {
            try {
                let resp = await client.get(GET_LINK_COUNTRIES);
                if (resp && resp.data && resp.status === 200) {
                    let tempCountries: Array<string> = resp.data;
                    let selectData: Array<SelectFieldData> = tempCountries.map(
                        (item: string) => {
                            let temp: SelectFieldData = {
                                label: item,
                                value: item,
                            };
                            return temp;
                        }
                    );
                    setCountries([{ label: 'All', value: '' }, ...selectData]);
                }
            } catch (error) {
                console.log(error);
                toast.error('Something went wrong!');
            }
        };
        fetchData();
        fetchCategories();
        fetchCountries();
    }, []);

    useEffect(() => {
        if (search) {
            let tempFilteredLinks = links.filter((item: Link) =>
                item.title.trim().includes(search.trim())
            );
            setFilteredLinks(tempFilteredLinks);
        } else {
            setFilteredLinks(links);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        let tempFilteredLinks: Array<Link> = [];
        if (filters) {
      
            tempFilteredLinks = links.filter((item: Link) => {
                return (
                    (filters['country']
                        ? item.country.includes(filters['country'])
                        : true) &&
                    (filters['category'] && filters['category'].length > 0 
                        ? filters['category'].includes(item.category)
                        : true) &&
                    (filters['minPrice']
                        ? Number.parseFloat(item.price) >= filters['minPrice']
                        : true) &&
                    (filters['maxPrice']
                        ? Number.parseFloat(item.price) <= filters['maxPrice']
                        : true) &&
                    (filters['minCf']
                        ? false
                        : true) &&
                    (filters['maxCf']
                        ? false
                        : true)
                );
            });

            setFilteredLinks(tempFilteredLinks);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, links]);

    const filterOnChangeHandler = (event: any,categoryList?:Array<Category>) => {
        if(!categoryList){
            let tempFilter: Filter = {
                ...filters,
                [`${event.target.name}`]: event.target.value,
            };
    
            setFilters(tempFilter);
            return;
        } 
        
        let array = categoryList.map((item:Category)=> item.name)
        let tempFilter: Filter = {
            ...filters,
            'category': array,
        };

        setFilters(tempFilter);

    };

    const resetFilters = () => {
        setFilters({
            country: '',
            category: [],
            minPrice: 0,
            maxPrice: 999999,
            minCf: 0,
            maxCf: 999999,
        });
    };
  return  (
    <div className="flex flex-col gap-8">
        <Header title="Favorite links" />
        <div>
            <form
                ref={formRef}
                className="my-5 flex flex-col xl:flex-row gap-4 rounded-3xl bg-[#fff] p-4"
            >
                <div className={'flex xl:w-1/3 flex-col gap-2'}>
                    <label className="text-sm font-bold text-[#1B1C19]">
                        Category
                    </label>
                    <Multiselect
                        className="focus:border-1 w-full rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-[6px] text-[#708191] focus:border-[#06D7A0]"
                        options={categories}
                        selectedValues={selectedCategories}
                        displayValue="name"
                        selectionLimit={10}
                        placeholder="Category"
                        onSelect={(selectedItem) => {
                            setSelectedCategories(selectedItem);
                            filterOnChangeHandler(null,selectedItem)
                        }}
                        onRemove={(selectedItem) => {
                            setSelectedCategories(selectedItem);
                            filterOnChangeHandler(null,selectedItem)
                        }}
                        showArrow
                        showCheckbox
                    />
                </div>
                <div className='flex xl:w-2/3 lg:flex-row flex-wrap flex-col gap-4 '>
                    <SelectField
                        items={countries}
                        className='h-2/5'
                        name="country"
                        title="Country"
                        value={filters.country + ''}
                        onValueChangeHandler={(event) =>
                            filterOnChangeHandler(event)
                        }
                    />
                    <div className="flex flex-col gap-2 h-[67px]">
                        <label className="text-sm font-bold text-[#1B1C19]">
                            Price
                        </label>
                        <div className="flex h-full gap-3">
                            <input
                                ref={minPriceRef}
                                className="focus:border-1 w-[110px] rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-1 text-[#708191] focus:border-[#06D7A0]"
                                type="number"
                                name="minPrice" 
                                placeholder={'0'}
                                value={filters.minPrice}
                                onChange={(event: any) =>
                                    filterOnChangeHandler(event)
                                }
                            />
                            <input
                                ref={maxPriceRef}
                                className="focus:border-1 w-[110px] rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-1 text-[#708191] focus:border-[#06D7A0]"
                                type="number"
                                name="maxPrice"
                                value={filters.maxPrice}
                                placeholder={'999999'}
                                onChange={(event: any) =>
                                    filterOnChangeHandler(event)
                                }
                            />
                        </div>
                    </div>
                    {/* <div className="flex flex-col gap-2 h-[67px]">
                        <label className="text-sm font-bold text-[#1B1C19]">
                            Cf
                        </label>
                        <div className="flex h-full gap-3">
                            <input
                                ref={minCf}
                                className="focus:border-1 w-[110px] rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-1 text-[#708191] focus:border-[#06D7A0]"
                                type="number"
                                placeholder={'0'}
                                name="minCf"
                                value={filters.minCf}
                                onChange={(event: any) =>
                                    filterOnChangeHandler(event)
                                }
                            />
                            <input
                                ref={maxCf}
                                className="focus:border-1 w-[110px] rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-1 text-[#708191] focus:border-[#06D7A0]"
                                type="number"
                                name="maxCf"
                                value={filters.maxCf}
                                placeholder={'999999'}
                                onChange={(event: any) =>
                                    filterOnChangeHandler(event)
                                }
                            />
                        </div>
                    </div> */}
                    <SecondaryButton
                        className="self-start mt-9 ml-auto pb-6 pr-6"
                        label="Reset"
                        type="button"
                        onClick={resetFilters}
                    />
                </div>
            </form>
            <TableHeader
                showSearch={true}
                setSearch={setSearch}
                totalRows={links.length}
                searchTotalRows={filteredLinks.length}
            ></TableHeader>
            <div className="d-flex w-full items-center justify-center">
                {loading && (
                    <CircularProgress
                        style={{
                            position: 'relative',
                            top: '30vh',
                            left: '50%',
                            color: '#06D7A0',
                        }}
                    />
                )}
                {!loading && filteredLinks && filteredLinks.length > 0 && (
                    <DataGrid
                        sx={{
                            background: '#FFF',
                            borderBottomRightRadius: '20px',
                            borderBottomLeftRadius: '20px',
                            borderTopLeftRadius: '0px',
                            borderTopRightRadius: '0px',
                            border: 'none',
                        }}
                        slots={{
                            loadingOverlay: LinearProgress,
                        }}
                        getRowId={(row: any) => row.sku}
                        rows={filteredLinks}
                        columns={columns}
                        disableRowSelectionOnClick
                        disableColumnMenu
                        loading={loading}
                        getRowClassName={(params: any) => {
                            let textClass =
                                'text-sm text-[#1B1C19] border-0 ';
                            let backPrimaryClass = 'bg-[#FFFFFF] ';
                            let backSecondaryClass = 'bg-[#FAFAFA] ';
                            return params.indexRelativeToCurrentPage % 2 ===
                                0
                                ? textClass + backPrimaryClass
                                : textClass + backSecondaryClass;
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 25 },
                            },
                        }}
                        pageSizeOptions={[25, 50, 100]}
                    />
                )}
                {filteredLinks &&
                    filteredLinks.length === 0 &&
                    !loading && (
                        <div className="flex w-full justify-center bg-[#fff] p-5 font-bold">
                            No data found
                        </div>
                    )}
            </div>
        </div>
    </div>
);
}

export default FavoriteLinks