import { GridRenderCellParams } from '@mui/x-data-grid';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import { Link } from '../marketplace/Marketplace.interface';
import { Cart } from 'src/utils/cart/Cart.interface';
import { generateRandomString } from 'src/utils/generateRandomString';
import { addToCart } from 'src/utils/cart/cartController';
import { useContext } from 'react';
import { CartContext } from 'src/context/CartContext';
import { toast } from 'react-toastify';

type Props = {
    params: GridRenderCellParams<Link>;
};

const TableBuyButton = ({ params }: Props) => {
    const { productList, setProductList } = useContext(CartContext);

    return (
        <PrimaryButton
            onClick={() => {
                let tempRow: Link = params.row;
                let tempProduct: Cart = {
                    id: generateRandomString(),
                    name: tempRow.title,
                    price: Number.parseFloat(tempRow.price),
                    sku: tempRow.sku,
                };

                addToCart(tempProduct);
                let tempProductList: Array<Cart> = [
                    ...productList,
                    tempProduct,
                ];
                setProductList(tempProductList);
                let message =
                    'You have succesfully added ' +
                    tempRow.title +
                    ' to the cart!';
                toast.success(message, {
                    position: 'bottom-right',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    theme: 'colored',
                });
            }}
            type="button"
            label="Buy"
            className="px-3 py-[8px]"
        />
    );
};

export default TableBuyButton;
