import { useForm } from 'react-hook-form';
import logo from 'src/assets/images/black-logo.svg';
import backImg from 'src/assets/images/login-back-image.svg';
import FormField from '../../components/formField/FormField';
import { LoginData, LoginResponse } from './Authentication.interface';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setSession } from 'src/utils/auth';
import { toast } from 'react-toastify';
import { useState } from 'react';

const Login = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginData>();

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onSubmit = async (data: LoginData) => {
        setIsLoading(true);
        let navigateTo = window.location.search
            ? window.location.search.replace('?referring=', '')
            : '/';
        try {
            const resp = await axios.post(
                'https://back-link.dtre.agency/wp-json/jwt-auth/v1/token',
                {
                    username: data.email,
                    password: data.password,
                }
            );
            setIsLoading(false);
            if (resp && resp.data && resp.status === 200) {
                let data: LoginResponse | undefined = resp.data.data;
                if (data) {
                    setSession(data.token, data.isValid);
                    if (data.isValid) {
                        navigate(navigateTo);
                    } else {
                        navigate('/create-profile');
                    }
                }
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
            toast.error('Incorrect email or password');
        }
    };

    return (
        <div className="flex h-screen w-screen flex-col sm:flex-row">
            <div className="flex w-full  flex-col items-center justify-center sm:w-1/2 sm:bg-[#DEFD71]">
                <img
                    className="w-1/2 p-8 sm:w-full sm:p-12 md:w-1/2 md:p-0 lg:w-1/2 xl:w-1/3"
                    src={logo}
                    alt="d_tre"
                />
                <img
                    className="hidden w-full sm:flex md:w-3/4 lg:w-2/3 xl:w-1/2"
                    src={backImg}
                    alt="login"
                />
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-14 sm:w-1/2">
                <h1 className="text-3xl sm:text-4xl">Sign in</h1>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex w-full flex-col gap-5 px-3 md:w-4/5 md:px-0 lg:w-2/3 xl:w-1/2"
                >
                    <FormField
                        name="email"
                        register={register}
                        type="text"
                        label="E-mail"
                        placeholder="Enter your e-mail"
                        required
                        errors={errors}
                    />

                    <FormField
                        name="password"
                        register={register}
                        type="password"
                        label="Password"
                        placeholder="Enter your password"
                        required
                        errors={errors}
                    />

                    {/* <div className="flex items-center gap-2">
                        <input
                            className="h-5 w-5 rounded-3xl border border-solid border-[#DDD] bg-[#FFF] hover:cursor-pointer"
                            type="checkbox"
                        />
                        <label className="text-sm font-normal">
                            Remember me
                        </label>
                    </div> */}
                    <PrimaryButton
                        type={'submit'}
                        label="Sign in to account"
                        isLoading={isLoading}
                    />
                    {/* <div className="flex items-center justify-between ">
                        <p className="text-sm font-normal">
                            Don't have an account yet?
                        </p>
                        <Link
                            to={'/sign-up'}
                            className="text-bold text-right text-sm font-bold text-[#06D7A0] hover:text-[#06d79f8e]"
                        >
                            Create a free account
                        </Link>
                    </div> */}
                </form>
            </div>
        </div>
    );
};

export default Login;
