import bell from 'src/assets/icons/bell-regular.svg';
import cart from 'src/assets/icons/cart-shopping-regular.svg';
import profile from 'src/assets/icons/round-person.svg';
import logout from 'src/assets/icons/round-logout.svg';
import Badge from '@mui/material/Badge';
import { Popover } from '@mui/material';
import { useContext, useRef, useState } from 'react';
import CartPopover from '../popovers/CartPopover';
import { CartContext } from 'src/context/CartContext';
import { getCart } from 'src/utils/cart/cartController';
import { logOut } from 'src/utils/auth';
import { useNavigate } from 'react-router-dom';
import bars from 'src/assets/icons/bars-regular.svg';
import { MenuContext } from 'src/context/MenuContext';
import NotificationPopover from '../popovers/NotificationPopover';
import { NotificationContext } from 'src/context/NotificationContext';
import { NotificationModel } from 'src/interfaces/Notification.interface';

type Props = {
    title: string;
    subtitle?:string
};

const Header = ({ title, subtitle }: Props) => {
    const [cartOpen, setCartOpen] = useState<boolean>(false);
    const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
    const { productList, setProductList } = useContext(CartContext);
    const { notificationList } = useContext(NotificationContext);
    const { setIsMenuOpened } = useContext(MenuContext);
    const cartButton = useRef(null);
    const notificationButton = useRef(null);
    const navigate = useNavigate();

    return (
        <header className="flex w-full flex-col justify-between gap-2 xs:flex-row xs:items-center">
            <div className='order-2 self-center  xs:order-1'>
                <h1 className=" text-3xl font-bold leading-10 text-[#1B1C19]">
                    {title}
                </h1>
                {subtitle && 
                    <p className=" text-base font-semibold text-[#1B1C19]">{subtitle}</p>
                }
            </div>
            <div className="shadow-[#7090b0]/8 order-1 flex h-20 items-center justify-between gap-6 self-end rounded-3xl bg-white px-4 py-2 shadow xs:order-2">
                <button
                    className="flex sm:hidden"
                    onClick={() => setIsMenuOpened(true)}
                >
                    <img src={bars} alt="Menu" className="menu-icon h-6 w-6" />
                </button>
                <div
                    ref={cartButton}
                    onClick={() => setCartOpen(true)}
                    className="flex items-center justify-center hover:cursor-pointer"
                    title="Cart"
                >
                    <Badge
                        sx={{
                            '& .MuiBadge-badge': {
                                color: 'white',
                                backgroundColor: '#FF7E66',
                            },
                        }}
                        badgeContent={
                            productList && productList.length
                                ? productList.length
                                : 0
                        }
                        className="cursor-pointer"
                    >
                        <img className="h-6 w-7" src={cart} alt="Cart" />
                    </Badge>
                </div>
                <Popover
                    id={'cart'}
                    open={cartOpen}
                    anchorEl={cartButton.current}
                    onClose={() => setCartOpen(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    className="w-full"
                >
                    <CartPopover
                        productList={getCart()}
                        setProductList={setProductList}
                    ></CartPopover>
                </Popover>
                <div
                    className="flex items-center justify-center hover:cursor-pointer"
                    title="Notification"
                    ref={notificationButton}
                    onClick={() => setNotificationOpen(true)}
                >
                    <Badge
                        sx={{
                            '& .MuiBadge-badge': {
                                color: 'white',
                                backgroundColor: '#FF7E66',
                            },
                        }}
                        badgeContent={
                            notificationList
                                ? notificationList.filter(
                                      (item: NotificationModel) =>
                                          !item.isViewed
                                  ).length
                                : 0
                        }
                        className="cursor-pointer"
                    >
                        <img className="h-6 w-7" src={bell} alt="Bell" />
                    </Badge>
                </div>
                <Popover
                    id={'notification'}
                    open={notificationOpen}
                    anchorEl={notificationButton.current}
                    onClose={() => setNotificationOpen(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    className="w-full"
                >
                    <NotificationPopover></NotificationPopover>
                </Popover>
                <button
                    className="flex items-center justify-center hover:cursor-pointer"
                    title="Profile"
                    onClick={() => navigate('/profile')}
                >
                    <img className="h-9 w-9" src={profile} alt="Profile" />
                </button>
                <button
                    onClick={() => {
                        logOut();
                        navigate('/sign-in');
                    }}
                    title="Logout"
                    className="flex items-center justify-center hover:cursor-pointer"
                >
                    <img className="h-7 w-8" src={logout} alt="Logout" />
                </button>
            </div>
        </header>
    );
};

export default Header;
