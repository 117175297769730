import { useNavigate } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { CartContext } from 'src/context/CartContext';
import { removeBillingInfo } from 'src/utils/cart/billingInfoController';
import { removeAllProducts } from 'src/utils/cart/cartController';
import { removeLinkAdditionalInfo } from 'src/utils/cart/additionalInfoController';

const Confirmation = () => {
    const navigate = useNavigate();
    const { setProductList } = useContext(CartContext);

    useEffect(() => {
        removeBillingInfo();
        removeAllProducts();
        removeLinkAdditionalInfo();
        setProductList([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="flex h-full w-full items-center justify-center">
            <div className="flex flex-col items-center rounded-lg bg-[#fff] p-32 shadow-md">
                <h1 className="text-center text-4xl font-semibold text-[#1B1C19]">
                    Booking Completed!
                </h1>
                <h3 className="mb-10 mt-4 text-center text-base font-semibold text-[#1B1C19]">
                    Thank you. Your order has been received.
                </h3>
                <button
                    className=" text-center text-base font-semibold text-[#06D7A0] hover:text-[#06d79fb4]"
                    onClick={() => navigate('/marketplace')}
                >
                    Back to marketplace
                </button>
            </div>
        </div>
    );
};

export default Confirmation;
